import { useEffect, useState } from "react";
import { Outlet, useNavigate, useLocation, Navigate, generatePath } from "react-router-dom";
import NavBar from "./NavBar";
import { REGIONS, useRegion } from "src/hooks/useRegion";
import { euTheme, usTheme } from "src/themes";
import { ThemeProvider } from "@mui/material";


export default function Root() {
    const navigate = useNavigate();
    const [navBarValueCtrl, setNavBarValueCtrl] = useState('');
    const location = useLocation();
    const region = useRegion();

    useEffect(() => {
        const locationElms = location.pathname.split("/");
        if (locationElms.length <= 3 || locationElms[2] !== "companies") {
            setNavBarValueCtrl("");
        }
    }, [location]);

    if (useLocation().pathname === "/") return <Navigate to={generatePath("/:region", { region: REGIONS[0] })} replace />;
    else {
        const theme = region === 'us' ? usTheme : euTheme; 
        return (
            <ThemeProvider theme={theme}>
                <div className="Root">
                    <NavBar onCompanyChange={(c) => navigate(`/${region}/companies/${c.u_company_id}/${c.sys_id}/invoices`)} onTitleClick={() => navigate(`/${region}/`)} onSeeMore={() => navigate(`/${region}/advanced-search?search=${navBarValueCtrl}`)} navBarValueCtrl={navBarValueCtrl} setNavBarValueCtrl={setNavBarValueCtrl} />
                    <Outlet />
                </div>
            </ThemeProvider>
        );
    }
}
