import React, { useState } from "react";
import { Link } from "react-router-dom";

import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton, { ListItemButtonProps } from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import HomeIcon from '@mui/icons-material/Home';
import StorageIcon from '@mui/icons-material/Storage';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from "@mui/material/Collapse";
import useRegionPath from "src/hooks/useRegionPath";
import { ManageSearch } from "@mui/icons-material";
import LoadingAnimation from "src/components/Skeleton/LoadingAnimation";
import { styled } from "@mui/material";
import { findSearchTermInWindowUrl } from "src/util/misc";
import { useProductCatalog } from "src/hooks/useProductCatalog";

const StyledDrawer = styled(Drawer)`
    & .MuiDrawer-paper {
        width: 250px;
        box-sizing: border-box;
    }
    width: 250;
    flex-shrink: 0;
`;

const StyledChevronBox = styled(Box)`
    cursor: pointer;
    border-radius: 50px;
    position: absolute;
    right: 10px;
    padding: 6px;
    
    &:hover {
        background-color: rgb(0, 0, 0, 0.08);
    }
`;




export interface ISiteMenuDrawerProps {
    menuDrawerState: boolean;
    setMenuDrawerState: Function;
}

export default function SiteMenuDrawer(props: ISiteMenuDrawerProps) {
    const [catalogOpen, setCatalogOpen] = useState(false);
    const region = useRegionPath();
    let catalogList = useProductCatalog();

    if (catalogList instanceof Error) throw catalogList;

    catalogList = catalogList || [];
    let catalogOptions = Array.from(new Set(catalogList.map(el => el.u_product_family)))
    const catalogOptionsToKeep = [
        "backup",
        "managed infrastructure",
        "cloud",
        "security",
        "end user support",
        "microsoft 365",
        "disaster recovery",
        "compliance",
        "it automation",
        "acct mgmt"
    ]
    catalogOptions = catalogOptions.filter(el => catalogOptionsToKeep.includes(el.toLowerCase()));
    catalogOptions = sortProductFamilyOptions(catalogOptions);

    const handleItemClick = () => {
        props.setMenuDrawerState(false);
    }

    const handleCollapseClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setCatalogOpen(!catalogOpen);
    };

    return (
        <StyledDrawer
            anchor="left"
            open={props.menuDrawerState}
            onClose={() => props.setMenuDrawerState(false)}
        >
            <Toolbar />
            <Box sx={{ overflow: "auto" }}>
                <List>
                    <NavMenuItem
                        text="Home"
                        icon={<HomeIcon />}
                        to={`/${region}/`}
                        onClick={handleItemClick}
                    />
                    <NavMenuItem
                        text="Recent Wins"
                        icon={<LocalFireDepartmentIcon />}
                        to={`/${region}/recent-wins`}
                        onClick={handleItemClick}
                    />
                    <NavMenuItem
                        text="Advanced Search"
                        icon={<ManageSearch />}
                        to={`/${region}/advanced-search`}
                        onClick={handleItemClick}
                    />
                    <NavMenuItem
                        text="Product Catalog"
                        icon={<MenuBookIcon />}
                        to={`/${region}/product-catalog${findSearchTermInWindowUrl() ? "?searchterm=" : ""}`}
                        onClick={handleItemClick}
                        suffix={
                            <>
                                <Box sx={{ flexGrow: 1 }} />
                                <StyledChevronBox onClick={handleCollapseClick}>
                                    {catalogOpen ? <ExpandLess /> : <ExpandMore />}
                                </StyledChevronBox>
                            </>
                        }
                    />
                    <Collapse in={catalogOpen} timeout="auto" unmountOnExit>
                        {
                            !catalogOptions.length ?
                                <ListItem disablePadding sx={{ ps: 5, width: 1, fontSize: '1.4rem' }}>
                                    <LoadingAnimation />
                                </ListItem>
                                :
                                catalogOptions.map(el => (
                                    <NavMenuItem
                                        key={el}
                                        text={ el }
                                        to={`/${region}/product-catalog?product-family=${el}`}
                                        buttonProps={{ sx: { pl: 7 } }}
                                        onClick={handleItemClick}
                                    />
                                ))
                        }
                    </Collapse>
                    <NavMenuItem
                        text="Data Centers"
                        icon={<StorageIcon />}
                        to={`/${region}/data-centers`}
                        onClick={handleItemClick}
                    />
                </List>
                <Divider />
            </Box>
        </StyledDrawer>
    );
};




interface INavMenuItemProps {
    text: string;
    icon?: React.ReactNode;
    to: string;
    onClick?: React.MouseEventHandler<HTMLAnchorElement>;
    suffix?: React.ReactNode;
    buttonProps?: ListItemButtonProps;
}


function NavMenuItem({ text, icon, to, onClick, suffix, buttonProps }: INavMenuItemProps) {
    const wrappedIcon = icon ? <ListItemIcon>{icon}</ListItemIcon> : null;

    return (
        <Link
            to={to}
            style={{ textDecoration: 'inherit', color: 'inherit' }}
            onClick={onClick}
        >
            <ListItem disablePadding>
                <ListItemButton {...buttonProps}>
                    {wrappedIcon}
                    <ListItemText primary={text} />
                    {suffix}
                </ListItemButton>
            </ListItem>
        </Link>
    )
}





export function sortProductFamilyOptions(options: string[]) {
    const sort: { [index: string]: number } = { cloud: 1, security: 2 }
    return options.sort((a, b) => {
        const aSort = sort[a] || 3;
        const bSort = sort[b] || 3;
        return aSort === bSort ?
            a.toLowerCase() < b.toLowerCase() ? -1 : 1 :
            aSort < bSort ? -1 : 1;
    })
}