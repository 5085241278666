import CompanyHeader from './CompanyHeader/CompanyHeader';
import { Outlet, useNavigate } from 'react-router-dom';

import type { CompanyIndeterminate } from '../../types';
import { useState, useRef, useEffect } from 'react';

import { Tabs, Typography, tabsClasses  } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Box from "@mui/material/Box";
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Tab from '@mui/material/Tab';
import useMediaQuery from '@mui/material/useMediaQuery';

import useLocationElementExtractor from 'src/pages/Companies/hooks/useLocationElementExtractor';
import AboutInfoDrawer from './About/AboutDrawer';
import CompanyHeaderSkeleton from '../../components/Skeleton/CompanyHeaderSkeleton';
import { useCompany } from 'src/hooks/useCompany';
import { useCompanyIdFromPathAssertively } from 'src/hooks/useCompanyIdFromPath';

const drawerWidthPx = "450px";
const companyTabs = ['invoices', 'orders', 'contracts', 'communications', 'services'] as const;



export default function CompanyPage() {
    const navigate = useNavigate();
    const { currentValue: currentTab } = useLocationElementExtractor("tab")
    const { currentValue: urlUCompanyId, newUrl } = useLocationElementExtractor('u_company_id', "REPLACE_IF_NEEDED")

    const companyId = useCompanyIdFromPathAssertively();
    const companyData = useCompany(companyId);
    const initialTab = currentTab || companyTabs[0];

    useEffect(() => {
        if(!companyData || companyData instanceof Error) return;
        // if the u_company_id portion of the URL is out of sync with the company result, update the URL. 
        // newUrl checked to rule out undefined type.  Always expected to be populated.  
        if (urlUCompanyId !== companyData.u_company_id && newUrl) {
            navigate(newUrl.replace("REPLACE_IF_NEEDED", companyData.u_company_id), { replace: true })
        }
    }, [urlUCompanyId, companyData, newUrl, navigate])

    // Need to check outletWidth for Scroller component in Invoices.  Checking here because ref can be inspected before invoices rendered
    const [outletWidth, setOutletWidth] = useState(0)
    const ref = useRef<HTMLDivElement>(null)

    // when ref loads, add an observer that updates outletWidth on resizes
    useEffect(() => {
        const myObserver = new ResizeObserver(entries => {
            entries.forEach(entry => {
                setOutletWidth(entry.contentRect.width); 
            });
        });        
        if(ref?.current) myObserver.observe(ref?.current);
    }, [ref])

    return (
        <CompanyDisplay
            initialTab={initialTab as any}
            companyData={companyData}
            onTabChange={(_, tab) => navigate(tab)}
        >
            <Box ref={ref}>
                <Outlet context={outletWidth}/>
            </Box>
        </CompanyDisplay>
    );
};



interface ICompanyDisplay {
    children: React.ReactNode,
    initialTab: typeof companyTabs[number],
    companyData: CompanyIndeterminate,
    onTabChange: (event: React.SyntheticEvent, value: any) => void
}

function CompanyDisplay(props: ICompanyDisplay) {
    const [isAboutOpen, setAboutOpen] = useState(false);

    const theme = useTheme();
    const screenSizeSmall = useMediaQuery(theme.breakpoints.down('lg'));
    const smallScreenTabsSx = !screenSizeSmall ? {} : { "& .MuiTab-root": {fontSize: '1.1rem' } };
    const mainContentWidth = screenSizeSmall ? "100%" : `calc(100% - ${drawerWidthPx})`;
    const mainContentPX = screenSizeSmall ? "5%" : "3%";

    const coditionalWidth = screenSizeSmall ? {} : {width:'100%', maxWidth: drawerWidthPx}; 

    return (
        <Box>
            <Box sx={{ display: "flex" }}>
                <Box sx={{ pt: '1rem', px: mainContentPX, width: mainContentWidth, boxSizing: "border-box" }}>
                    {
                        /* Error Display is an empty component here.
                        Any other message would confuse the user.
                        Header _not totally necessary_ if other components load */
                        props.companyData instanceof Error ? <></> 
                            :(props.companyData == null 
                                ? <CompanyHeaderSkeleton /> 
                                : <CompanyHeader company={props.companyData} />
                            )
                    }
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', justifyContent: 'flex-start', width: '100%' }}>
                        <Tabs
                            variant="scrollable"
                            scrollButtons="auto"
                            allowScrollButtonsMobile
                            onChange={props.onTabChange}
                            value={props.initialTab}
                            aria-label="tabs switch"
                            sx={{ 
                                width: '100%', 
                                ...smallScreenTabsSx, 
                                [`& .${tabsClasses.scrollButtons}`]: {
                                    '&.Mui-disabled': { opacity: 0.3 },
                                  },
                              }}
                        >
                            {generateTabsArr()}
                        </Tabs >
                        {screenSizeSmall && <AboutButton onClick={() => setAboutOpen(true)} />}
                    </Box>
                    <Box sx={{ py: 2 }}>
                        {props.children}
                    </Box>
                </Box>
                <Box sx={{...coditionalWidth}}>
                    <AboutInfoDrawer
                        companyData={props.companyData}
                        isOpen={isAboutOpen}
                        setOpen={setAboutOpen}
                        drawerType={!screenSizeSmall ? 'permanent' : 'temporary'}
                        drawerWidthPx={drawerWidthPx}
                    />
                </Box>
            </Box>
        </Box>
    );
}




function generateTabsArr() {

    return companyTabs.map((tab) => (
        <Tab
            key={tab}
            label={tab}
            value={tab}
            sx={{px: 2}}
        />
    ));
}



function AboutButton(props: { onClick: React.MouseEventHandler<HTMLButtonElement> }) {
    return (
        <IconButton
            sx={{
                opacity: 0.75,
                mr: 0, pr: 0
            }}
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={props.onClick}
        >
            <ChevronLeftIcon />
            <Typography
                fontSize="small"
                variant="button"
            >
                About
            </Typography>
        </IconButton>
    );
}