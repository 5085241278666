import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

import OrdersDisplay from './OrdersDisplay';
import InvoiceOrderSkeleton from '../../../components/Skeleton/InvoiceOrderSkeleton';
import isTouchScreen from '../../../hooks/isTouchScreen';
import { addHashToWindowUrl, removeHashFromWindowUrl } from 'src/util/misc';
import { makeMonthCutoff } from '../../../util/timeUtils';

import type { Order } from '../../../types';
import { useCompanyIdFromPathAssertively } from 'src/hooks/useCompanyIdFromPath';
import assertively from 'src/util/assertively';
import { useOrders } from 'src/hooks/useOrders';


export default function Orders() {
    const [openOrders, setOpenOrders] = useState<string[]>([]);
    const companyId = useCompanyIdFromPathAssertively();
    const orders = assertively(useOrders(companyId));
    const hash = useLocation().hash.replace("#", "");

    useEffect(() => {
        if (hash) setOpenOrders([hash]);
    }, [hash])

    const onOrderClick = (orderNumber: string) => {
        if (openOrders.includes(orderNumber)) {
            setOpenOrders(openOrders.filter((num) => num !== orderNumber));
            removeHashFromWindowUrl();
        } else {
            setOpenOrders([...openOrders, orderNumber]);
            addHashToWindowUrl(orderNumber);
        }
    }


    if(!orders) return <InvoiceOrderSkeleton count={5} />;
    const sortedOrders = filterAndSortOrders(orders);

    return (
        <OrdersDisplay
            orders={sortedOrders}
            numberOpen={hash}
            onChangeFn={onOrderClick}
            isTouchScreen={isTouchScreen()}
            openOrders={openOrders}
        />
    )
}

/**
 * filter out orders that are not in the last 6 months or next 3 months and sort by date
 */
function filterAndSortOrders(orders: Order[]): Order[] {
    const prevSixMonthsCutoff = makeMonthCutoff(new Date(), -6);
    const nextThreeMonthCutoff = makeMonthCutoff(new Date(), 4);

    return orders.filter((o) => {
        const orderDate = new Date(o.sys_created_on_UTC_TZ);
        return prevSixMonthsCutoff < orderDate && nextThreeMonthCutoff > orderDate;
    }).sort((a, b) => {
        const aDate = new Date(a.sys_created_on_UTC_TZ);
        const bDate = new Date(b.sys_created_on_UTC_TZ);
        return aDate > bDate ? -1 : 1;
    });
}
