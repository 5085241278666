import React, { useState } from "react";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import type { } from "@mui/lab/themeAugmentation";
import CompanyBadges from "../../../Companies/About/CompanyBadges";
import Grid from "@mui/material/Grid";


import type { CompanyOption } from "../../../../types";
import { CircularProgress } from "@mui/material";

import { deepmerge } from '@mui/utils';
import filterAndSortCompanies from "src/util/search-companies";

interface Props {
    onCompanyChange: (val: CompanyOption) => void;
    companyList: null | CompanyOption[] | Error;
    screenSizeSmall: boolean;
    navBarValueCtrl: string;
    setNavBarValueCtrl: React.Dispatch<React.SetStateAction<string>>,
    onSeeMore: () => void;
}

const autoCompleteTheme = ({
    components: {
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    border: "2px solid #F3F3F6",
                    borderRadius: "50px",
                },
                input: {
                    color: "#F3F3F6",
                },
                inputRoot: {
                    borderRadius: "50px",
                },
                clearIndicator: {
                    color: "#F3F3F6",
                },
                paper: {
                    minWidth: "250px"
                }
            },
        },
    }
});


const CompanySearchBar = (props: Props) => {
    const { 
        companyList, onCompanyChange, screenSizeSmall, navBarValueCtrl: inputValueCtrl,
        setNavBarValueCtrl: setInputValueCtrl, onSeeMore 
    } = props;
    const [autoCompleteListOpen, setAutoCompleteListOpen] = useState(false);
    // const [companyLimit, setCompanyLimit] = useState<10 | 99999>(100);
    const companyLimit = 20;
    const displayLoading = autoCompleteListOpen && !companyList;
    const noOptionsText = companyList instanceof Error
        ? 'Something went wrong! Please reload the page and try again'
        : 'No Companies Found';

    const DISPLAY_MORE_MSG = `Displaying ${companyLimit} Companies.  Show More?`;

    return (
        <>
            <ThemeProvider theme={outerTheme => (createTheme(deepmerge(outerTheme, autoCompleteTheme)))}>
                <Autocomplete<CompanyOption | string>
                    id="companySearchBox"
                    data-testid='autocomplete-search'
                    inputValue={inputValueCtrl}
                    popupIcon={""}
                    open={autoCompleteListOpen}
                    sx={{
                        flexGrow: 2
                    }}
                    size="small"
                    autoComplete={true}
                    options={Array.isArray(companyList) ? companyList : []}
                    loading={displayLoading}
                    noOptionsText={noOptionsText}
                    blurOnSelect={true}
                    onInputChange={(event, inputValue, reason) => {
                        // this if branch controls text entry.  result lists are limited to 100
                        if (reason === "input") {
                            setInputValueCtrl(inputValue);
                            if (inputValue.length) {
                                // setCompanyLimit(100);
                                setAutoCompleteListOpen(true);
                            }
                            else setAutoCompleteListOpen(false);
                            // this condition isolates the input clear event
                        } else if (reason === "reset" && !inputValue && !event) {
                            setInputValueCtrl('');
                            setAutoCompleteListOpen(false);
                        }
                    }}
                    onChange={(event, value) => {
                        setAutoCompleteListOpen(false);
                        // this condition occurs when the Display More option is selected, i.e. the value is type string and not a CompanyOption object
                        if (typeof value == 'string') {
                            onSeeMore();
                        } else if (value) {
                            setInputValueCtrl(value.name)
                            onCompanyChange(value);
                        }
                    }}
                    filterOptions={(options, { inputValue }) => {
                        // The initial options array never actually contains a string, so this is a safe cast
                        const realOpts = options as CompanyOption[];

                        if (inputValue.length > 0) {
                            const newOpts: (CompanyOption|string)[] = filterAndSortCompanies(realOpts, inputValue, companyLimit + 1);
                            if (newOpts.length > companyLimit) newOpts[companyLimit] = DISPLAY_MORE_MSG;
                            return newOpts;
                        } else {
                            return options;
                        }
                    }}
                    getOptionLabel={(option: CompanyOption | string) => {
                        if (typeof option != "string")
                            return option?.name || "";
                        else return option;
                    }}
                    renderOption={(props, option: CompanyOption | string, state) => {
                        if (state.inputValue.length > 0) {
                            if (typeof option === 'string') return (
                                <Grid container component="li" {...props} >
                                    <Grid item xs={12}>
                                        <b>{option}</b>
                                    </Grid>
                                </Grid>
                            )
                            return (
                                <Grid container component="li" {...props} data-testid={'company-list-item'} >
                                    <Grid item xs={12} sm={6}>
                                        {option.name}
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <CompanyBadges
                                            company={option}
                                            justifyContentSetting={screenSizeSmall ? "flex-start" : 'flex-end'}
                                            chipProps={screenSizeSmall
                                                ? { sx: { padding: '0px', fontSize: '.8rem', height: '1rem', paddingY: '1px', marginLeft: 0 } }
                                                : {}
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            );
                        }
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder="Enter a company..."
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {displayLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    )}
                />
            </ThemeProvider>
        </>
    );
};

export default CompanySearchBar;
