import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { PublicationsDisplay } from "src/pages/Companies/Publications/PublicationsDisplay";
import InvoiceOrderSkeleton from "src/components/Skeleton/InvoiceOrderSkeleton";
import isTouchScreen from "src/hooks/isTouchScreen";
import { useCompanyIdFromPathAssertively } from "src/hooks/useCompanyIdFromPath";
import { usePublications } from "src/hooks/usePublications";
import { Publication } from "src/types";
import assertively from "src/util/assertively";
import { addHashToWindowUrl, removeHashFromWindowUrl } from "src/util/misc";


export default function Publications() {
    const companyId = useCompanyIdFromPathAssertively();
    const publications = assertively(usePublications(companyId));

    const [openPubs, setOpenPubs] = useState<string []>([]); 
    const hash = useLocation().hash.replace("#", "");


    useEffect(() => {
        if (hash) setOpenPubs([hash]);
    }, [hash])

    const onPubClick = (pubNumber: string) => {
        if (openPubs.includes(pubNumber)) {
            setOpenPubs(openPubs.filter((num) => num !== pubNumber));
            removeHashFromWindowUrl();
        } else {
            setOpenPubs([...openPubs, pubNumber]);
            addHashToWindowUrl(pubNumber);
        }
    }

    if (!publications) return <InvoiceOrderSkeleton count={10} />;
    const publicationsArr = Object.entries(publications).map(([key, value]) => value);
    const pubArrSorted = sortPubs(publicationsArr);
    return <PublicationsDisplay publications={pubArrSorted} onChangeFn={onPubClick}
        numberOpen={hash} isTouchScreen={isTouchScreen()} openPublications={openPubs}/>;
}


function sortPubs(publications: Publication[]){
    return publications.sort((a, b) => {
        const aDate = new Date(a.publish_date);
        const bDate = new Date(b.publish_date);
        return aDate > bDate ? -1 : 1;
    })
}