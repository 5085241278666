import React, { useRef, useEffect } from 'react';

import type { Contract, ContractDetails } from '../../../types';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import { grey, orange } from '@mui/material/colors'; 
import ErrorIcon from '@mui/icons-material/Error';

import ContractDetailsSkeleton from 'src/components/Skeleton/ContractDetailsSkeleton';

import useMediaQuery from '@mui/material/useMediaQuery';
import { Chip, useTheme } from '@mui/material';
import { useContractDetails } from 'src/hooks/useContracts';


// export type ContractDetailsContainer = DetailsContainer<ContractDetails>;

interface IContractsProps {
    contracts: Contract[],
    numberOpen: string | undefined,
    onChangeFn: (orderNumber: string) => void,
    isTouchScreen: boolean, 
    openContracts: string[],
}

export default function ContractsDisplay({ contracts, numberOpen, onChangeFn, isTouchScreen, openContracts }: IContractsProps) {
    const accordionSx = {
        boxShadow: 0.5,
        border: 1,
        borderColor: '#e6e6e6',
        borderRadius: 1,
        margin: 1,
        backgroundColor: grey[50], 
        '&:before': { display: 'none' }
    };
    const additionalSummarySx = isTouchScreen
        ? {}
        : { '&:hover': { backgroundColor: '#EAEAEA', borderRadius: '3px' } };


    let scrollToOpenRef = useRef<HTMLDivElement>(null);

    // only control scroll on first render
    useEffect(() => {
        scrollToOpenRef.current?.scrollIntoView();
    }, []);

    const isScreenSmall = useMediaQuery(useTheme().breakpoints.down("md"));

    return (
        !contracts.length ? <Box sx={{ fontSize: '1.8rem', margin: 2 }}>No Contracts Found.</Box> :
            <Box>
                {
                    contracts.map((contract, index) => {
                        const currencyTotal = parseFloat(contract.u_contract_value_local); 
                        const monthRemainingResult = contract.ends ? findMonthsRemaining(contract.ends, 6) : null;
                        return (
                            <Accordion
                                ref={contract.number === numberOpen ? scrollToOpenRef : null}
                                key={contract.number}
                                sx={accordionSx}
                                expanded={openContracts.includes(contract.number)}
                                data-testid={`testId-${index}`}
                                onChange={() => {
                                    onChangeFn(contract.number)
                                }}
                                TransitionProps={{ unmountOnExit: true }}
                                >
                                <AccordionSummary
                                    aria-controls={`${contract.number}-content`}
                                    id={`${contract.number}-header`}
                                    sx={{
                                        border: 0,
                                        padding: 0,
                                        width: '100%',
                                        paddingX: '5px',
                                        paddingY: '7px',
                                        borderRadius: '4px',
                                        // see overriding nested component styles for explanation on selector: 
                                        // https://mui.com/material-ui/customization/how-to-customize/#the-sx-prop
                                        '& .MuiAccordionSummary-content': {
                                            margin: 0
                                        },
                                        ...additionalSummarySx
                                    }}
                                    disableRipple={false}
                                >
                                    <Stack sx={{ width: '100%', paddingLeft: 1, paddingRight: 1 }}>
                                        { isScreenSmall && monthRemainingResult != null && monthRemainingResult.status !== "greaterthanthreshold" && 
                                        <Box sx={{ width: '100%' }} >
                                            <Box sx={{ fontSize: '1.2rem', color: monthRemainingResult.status === "expired" || monthRemainingResult.result.includes("Days") ? "red" : orange[800], fontWeight: "bold" }}>
                                                {monthRemainingResult.result}
                                            </Box>
                                        </Box> }
                                        <Stack sx={{ width: '100%' }} flexDirection="row" justifyContent="space-between">
                                            <Box sx={{ 
                                                    fontSize: '1.2rem', 
                                                    color: "black"
                                                    }}>
                                                {`${new Date(contract.starts).toLocaleDateString(undefined, { timeZone: 'UTC' })}${
                                                    contract.ends 
                                                        ? ` - ${new Date(contract.ends).toLocaleDateString(undefined, { timeZone: 'UTC' })}` 
                                                        : " (No End Date)"}`
                                                }
                                            </Box>
                                            <Box sx={{ fontSize: '1.2rem' }}>
                                                {contract.u_contract_value_local_code}
                                            </Box>
                                        </Stack>
                                        <Stack direction="row" alignItems="center" sx={{ width: '100%' }}>
                                            <Box sx={{ fontSize: '1.8rem', fontWeight: 'bold', overflowWrap: 'break-word', hyphens: 'manual' }}>
                                                {contract.short_description}
                                            </Box>
                                            {/** Empty div with flexGrow to fill blank space */}
                                            <Box sx={{ flexGrow: 2 }}></Box>
                                            <Box sx={{ width: "40%", display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                { !isScreenSmall && monthRemainingResult != null && monthRemainingResult.status !== "greaterthanthreshold" 
                                                    ? (
                                                        monthRemainingResult.status === "expired" || monthRemainingResult.result?.includes("Days")
                                                            ? <Chip label={monthRemainingResult.result} size="small" sx={{backgroundColor: "#FFCCCB", fontWeight: 'bold'}} />
                                                            : <Chip label={monthRemainingResult.result} size="small" sx={{backgroundColor: orange[100], fontWeight: 'bold'}} />
                                                    )
                                                    : <Box sx={{ flexGrow: 2 }}></Box> }
                                                <Box sx={{ fontSize: '1.8rem', fontWeight: 'bold', marginLeft: 2 }}>
                                                    {currencyTotal.toLocaleString()}
                                                </Box>
                                            </Box>
                                        </Stack>
                                    </Stack>
                                </AccordionSummary>
                                <AccordionDetails sx={{ padding: '0px', mt: 1 }}>
                                    <ExpandedContract contract={contract} />
                                </AccordionDetails>
                            </Accordion>
                        )
                    })

                }
            </Box>
    );
}


interface IExpandedContractProps {
    contract: Contract,
}


function ExpandedContract({ contract }: IExpandedContractProps) {
    const contractDetails = useContractDetails(contract.sys_id);

    return (
        <Grid container sx={{ backgroundColor: '#EAEAEF', borderRadius: '4px' }}>
            <Grid item xs={0} md={1}></Grid>
            <Grid item xs={12} md={11}>
                <Grid container>
                    <Grid item xs={12} sm={3} >
                        <KeyValueDisplay label="Contract ID" value={contract.number} />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <KeyValueDisplay label="Start Date" value={
                            contract.starts ? new Date(`${contract.starts}`).toLocaleDateString(undefined, { timeZone: 'UTC' }) : ''
                        } />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <KeyValueDisplay label="End Date" value={
                            contract.ends ? new Date(`${contract.ends}`).toLocaleDateString(undefined, { timeZone: 'UTC' }) : ''
                        } />
                    </Grid>
                </Grid>
                <Grid item xs={1} md={1}></Grid>
                {
                    (contractDetails instanceof Error) ? <ContractDetailsError /> : 
                    !contractDetails ? <Grid container><ContractDetailsSkeleton count={5} /></Grid> :
                    !contractDetails.length ? <EmptyContractDetails /> : 
                        <ContractDetailsList contractDetails={contractDetails} />
                }
            </Grid>
        </Grid>
    );
}



function ContractDetailsList({ contractDetails }: { contractDetails: ContractDetails[] }) {
    const vertCellPad = "8px";
    const horzCellPad = "5px";

    return (
        <Grid item xs={12} sm={11}>
            <Box sx={{ fontSize: '1.4rem', paddingBottom: 2 }}>
                <Grid container>
                    <Grid item xs={3} sx={{ paddingY: vertCellPad, paddingX: horzCellPad }}>
                        Product ID
                    </Grid>
                    <Grid item xs={5} sx={{ paddingY: vertCellPad, paddingX: horzCellPad }}>
                        Description
                    </Grid>
                    <Grid item xs={2} sx={{ paddingY: vertCellPad, paddingX: horzCellPad, textAlign: 'end' }}>
                        Quantity
                    </Grid>
                    <Grid item xs={2} sx={{ paddingY: vertCellPad, paddingX: horzCellPad, textAlign: 'end' }}>
                        Unit Price
                    </Grid>
                    {
                        contractDetails.map((contractItem) => {
                            const _unitPrice = Number.parseFloat(contractItem.u_unit_price_local);
                            const unitPrice = Number.isNaN(_unitPrice) ? "—" : `${_unitPrice.toLocaleString()}`;

                            return (
                                <React.Fragment key={contractItem.u_number}>
                                    <Grid item xs={3} sx={{ paddingY: vertCellPad, paddingX: horzCellPad, fontWeight: "medium", fontSize: '1.6rem' }}>
                                        <Tooltip title={contractItem.u_product_id} placement="top-start">
                                            <Typography noWrap sx={{ fontWeight: "medium" }}>
                                                {contractItem.u_product_id}
                                            </Typography>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={5} sx={{ paddingY: vertCellPad, paddingLeft: horzCellPad, paddingRight: "10px", fontSize: '1.6rem' }}>
                                        <Tooltip title={contractItem.u_invoice_description} placement="top-start">
                                            <Typography noWrap sx={{ fontWeight: "medium" }}>
                                                {contractItem.u_invoice_description}
                                            </Typography>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={2} sx={{ paddingY: vertCellPad, paddingX: horzCellPad, fontWeight: "medium", fontSize: '1.6rem', textAlign: 'end' }}>
                                        <Tooltip title={`${contractItem.u_quantity}`} placement="top-end">
                                            <Typography noWrap sx={{ fontWeight: "medium" }}>
                                                {contractItem.u_quantity}
                                            </Typography>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={2} sx={{ paddingY: vertCellPad, paddingX: horzCellPad, fontWeight: "medium", fontSize: '1.6rem', textAlign: 'end' }}>
                                        <Tooltip title={`${unitPrice} ${contractItem.u_unit_price_local_code}`} placement="top-end">
                                            <Typography noWrap sx={{ fontWeight: "medium" }}>
                                                {unitPrice} {contractItem.u_unit_price_local_code}
                                            </Typography>
                                        </Tooltip>
                                    </Grid>
                                </React.Fragment>
                            )
                        })
                    }
                </Grid>
            </Box>

        </Grid>
    );
}


function ContractDetailsError() {
    return (
        <Typography variant='h6' sx={{ margin: 2 }}>
            <ErrorIcon /> Error requesting contract details. Reload page to try again.
        </Typography>
    );
}


function EmptyContractDetails() {
    return (
        <Typography variant='body2' sx={{ margin: 2 }}>
            No contract details found.
        </Typography>
    );
}


interface KeyValueDisplayProps {
    label: any,
    value: any
}

function KeyValueDisplay(props: KeyValueDisplayProps) {
    const label = props.label;
    const value = props.value;
    return (
        <Stack sx={{ margin: "6px" }}>
            <Box>
                <Typography >
                    {label}
                </Typography>
            </Box>
            <Box>
                <Typography sx={{ fontWeight: 'bold' }}>
                    {value}
                </Typography>
            </Box>
        </Stack>
    )
}

function findMonthsRemaining(dateString: string, monthsThreshold: number): { status: "expired" | "greaterthanthreshold" | "lessthanorequaltothreshold"; result: string } {
    const todayDate = new Date();
    // Normalize the datestring to have the same timestamp as the current date, to make date comparisons easier
    const date = new Date(dateString + "T00:00:00");
    date.setHours(todayDate.getHours());
    date.setMinutes(todayDate.getMinutes());
    date.setSeconds(todayDate.getSeconds());
    date.setMilliseconds(todayDate.getMilliseconds());

    if (date < todayDate) {
        // EXPIRED
        return {
            status: "expired",
            result: "Expired",
        };
    }

    //Calculate the differences between the start and end dates
    /**
     * Something to note is that the date get methods used have a local timezone offset. However, since
     * times are not relevant and I was able to set both dates to have the same time, as long as the offset affects both dates
     * when getting the year, month, and day, the offset won't matter.
     */
    var yearsDiff = date.getFullYear() - todayDate.getFullYear();
    var monthsDiff = date.getMonth() - todayDate.getMonth() + (12 * yearsDiff);
    var daysDiff = date.getDate() - todayDate.getDate();

    if (monthsDiff <= monthsThreshold) {
        let result = "";
        if(monthsDiff < 1 && daysDiff > 0) {
            // use days only (e.g. 20 days remaining)
            result = `${daysDiff} Day${daysDiff > 1 ? "s" : ""} Remaining`;
        }
        else if(monthsDiff === 0) {
            // Expires today
            result = "Expires Today";
        }
        else {
            // use months only (e.g. 2 months remaining)
            result = `${monthsDiff} Month${monthsDiff > 1 ? "s" : ""} Remaining`;
        }
        return {
            status: "lessthanorequaltothreshold",
            result: result,
        };
    } else {
        return {
            status: "greaterthanthreshold",
            result: "",
        };
    }
}