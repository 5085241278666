import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useReducer, useState } from "react";
import { CompanyOption } from "src/types";
import { BadgeObject } from "../../pages/Companies/About/CompanyBadges";
import FilterResultDisplay from "./FilterResultDisplay";
import { FilterDrawerDisplayAlt } from "./FiltersDrawerDisplay";
import { immutablyReplace } from "src/util/misc";
import { FILTERS_REDUCER_ACTION_TYPES } from "./FILTERS_REDUCER_ACTION_TYPES";

// Reducer Global Types and Initializations
export interface CheckboxState {
    name: string;
    checked: boolean;
}

export interface BadgeCheckboxState extends CheckboxState {
    group: string;
    element: JSX.Element;
}

export interface FiltersState {
    Status: BadgeCheckboxState[];
    Profile: BadgeCheckboxState[];
    Strategic: BadgeCheckboxState[];
    Channel: BadgeCheckboxState[];
    "Default Agents": CheckboxState[];
    "Master Agents": CheckboxState[];
    "Support Pod": CheckboxState[]; 
}

type FiltersReducerAction = {
    type: FILTERS_REDUCER_ACTION_TYPES;
    payload: number
};

const filtersReducer = (state: FiltersState, action: FiltersReducerAction): FiltersState => {
    switch (action.type) {
        case FILTERS_REDUCER_ACTION_TYPES["Status"]:
            return { ...state, Status: immutablyReplace(state.Status, action.payload, { ...state.Status[action.payload], checked: !state.Status[action.payload].checked }) };
        case FILTERS_REDUCER_ACTION_TYPES["Profile"]:
            return { ...state, Profile: immutablyReplace(state.Profile, action.payload, { ...state.Profile[action.payload], checked: !state.Profile[action.payload].checked }) };
        case FILTERS_REDUCER_ACTION_TYPES["Strategic"]:
            return { ...state, Strategic: immutablyReplace(state.Strategic, action.payload, { ...state.Strategic[action.payload], checked: !state.Strategic[action.payload].checked }) };
        case FILTERS_REDUCER_ACTION_TYPES["Channel"]:
            return { ...state, Channel: immutablyReplace(state.Channel, action.payload, { ...state.Channel[action.payload], checked: !state.Channel[action.payload].checked }) };
        case FILTERS_REDUCER_ACTION_TYPES["Default Agents"]:
            return { ...state, "Default Agents": immutablyReplace(state["Default Agents"], action.payload, { ...state["Default Agents"][action.payload], checked: !state["Default Agents"][action.payload].checked }) };
        case FILTERS_REDUCER_ACTION_TYPES["Master Agents"]:
            return { ...state, "Master Agents": immutablyReplace(state["Master Agents"], action.payload, { ...state["Master Agents"][action.payload], checked: !state["Master Agents"][action.payload].checked }) };
        case FILTERS_REDUCER_ACTION_TYPES["Support Pod"]:
            return { ...state, "Support Pod": immutablyReplace(state["Support Pod"], action.payload, { ...state["Support Pod"][action.payload], checked: !state["Support Pod"][action.payload].checked }) };
        case FILTERS_REDUCER_ACTION_TYPES["RESET"]:
            return {
                Status: state.Status.map((item) => ({ ...item, checked: false })),
                Profile: state.Profile.map((item) => ({ ...item, checked: false })),
                Strategic: state.Strategic.map((item) => ({ ...item, checked: false })),
                Channel: state.Channel.map((item) => ({ ...item, checked: false })),
                "Default Agents": state["Default Agents"].map((item) => ({ ...item, checked: false })),
                "Master Agents": state["Master Agents"].map((item) => ({ ...item, checked: false })),
                "Support Pod": state["Support Pod"].map((item) => ({...item, checked: false}))
            };
        default:
            console.error("Error setting filters. Contact dev team.");
            throw new Error();
    }
};

/**
 * Function that will calculate the number of filters selected
 */
const filterCount = (filtersState: FiltersState) => {
    let count = 0;
    for(const checkboxes of Object.values(filtersState) as CheckboxState[][]) {
        count += checkboxes.filter((item) => item.checked).length;
    }
    return count;
};

export type AdvancedSearchDisplayAltProps = {
    companiesData: Error | CompanyOption[] | null;
    companiesDataWithBadges: {
        badges: BadgeObject[];
        companyData: CompanyOption;
    }[];
    onCompanyClickFn: (sys_id: string) => void;
    initialFiltersState: FiltersState;
    initSearchBarState: string;
};

const AdvancedSearchDisplayAlt = (props: AdvancedSearchDisplayAltProps) => {
    //Handle loading
    const isLoading = props.companiesData === null ? true : false;

    //State
    const [filtersState, filtersDispatch] = useReducer(filtersReducer, props.initialFiltersState);

    // Create onChange handler for the filter's checkboxes
    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, filterName: FILTERS_REDUCER_ACTION_TYPES) => {
        //set checkbox state
        const index = Number(event.target.name);
        filtersDispatch({type: filterName, payload: index});
    };

    //Search Bar State and Functions
    const [searchBarState, setSearchBarState] = useState<string>(props.initSearchBarState);

    const resetAllFilters = (event: React.MouseEvent<HTMLButtonElement>) => {
        //code to reset all filters to checked state of false
        filtersDispatch({type: FILTERS_REDUCER_ACTION_TYPES["RESET"], payload: -1});
        setSearchBarState("");
    };

    //Handle drawer component for filters
    const [isFilterDrawerOpen, setFilterDrawerOpen] = useState(false);
    const theme = useTheme();
    const screenSizeSmall = useMediaQuery(theme.breakpoints.down("lg"));
    const drawerWidthPx = "450px";
    const mainContentWidth = screenSizeSmall ? "100%" : `calc(100% - ${drawerWidthPx})`;

    return (
        <Box sx={{ display: "flex", width: 1 }}>
            <Box sx={{ width: !screenSizeSmall ? drawerWidthPx : "auto" }}>
                <FilterDrawerDisplayAlt isOpen={isFilterDrawerOpen} isLoading={isLoading} setOpen={setFilterDrawerOpen} drawerType={!screenSizeSmall ? "permanent" : "temporary"} drawerWidthPx={drawerWidthPx} filtersState={filtersState} handleCheckboxChange={handleCheckboxChange} resetAllFilters={resetAllFilters} />
            </Box>
            <Box sx={{ width: mainContentWidth }}>
                <Box sx={{ p: 2 }}>
                    <FilterResultDisplay isLoading={isLoading} searchBarState={searchBarState} setSearchBarState={setSearchBarState} onCompanyClickFn={props.onCompanyClickFn} setFilterDrawerOpen={setFilterDrawerOpen} companiesDataWithBadges={props.companiesDataWithBadges} filtersState={filtersState} filterCount={filterCount}></FilterResultDisplay>
                </Box>
            </Box>
        </Box>
    );
};

export default AdvancedSearchDisplayAlt;
