import { useLocation, useOutletContext } from 'react-router-dom';
import { makeMonthCutoff } from '../../../util/timeUtils';
import InvoiceOrderSkeleton from '../../../components/Skeleton/InvoiceOrderSkeleton';
import { useEffect, useState } from 'react';

import InvoicesDisplay from './InvoicesDisplay';
import isTouchScreen from '../../../hooks/isTouchScreen';
import { addHashToWindowUrl, removeHashFromWindowUrl } from 'src/util/misc';

import type { Invoice } from '../../../types';
import { useCompanyIdFromPathAssertively } from 'src/hooks/useCompanyIdFromPath';
import assertively from 'src/util/assertively';
import { useInvoices } from 'src/hooks/useInvoices';


export default function Invoices() {
    const [openInvoices, setOpenInvoices] = useState<string []>([]); 
    const companyId = useCompanyIdFromPathAssertively();
    const invoices = assertively(useInvoices(companyId));

    const hash = useLocation().hash.replace("#", "");
    const outletWidth = useOutletContext<number>(); 

    useEffect(() => {
        if (hash) setOpenInvoices([hash]);
    }, [hash])

    if (!invoices) return <InvoiceOrderSkeleton count={10} />;
    const sortedInvoices = filterAndSortInvoices(invoices);

    const onInvoiceClick = (invoiceNumber: string) => {
        if (openInvoices.includes(invoiceNumber)) {
            setOpenInvoices(openInvoices.filter((num) => num !== invoiceNumber));
            removeHashFromWindowUrl();
        } else {
            setOpenInvoices([...openInvoices, invoiceNumber]);
            addHashToWindowUrl(invoiceNumber);
        }
    }


    return (
        <InvoicesDisplay
            invoices={sortedInvoices}
            u_numberOpen={hash}
            onChangeFn={onInvoiceClick}
            isTouchScreen={isTouchScreen()}
            scrollerContainerSize={outletWidth}
            companySysId={companyId}
            openInvoices={openInvoices}
        />

    )
}




/**
 * filter out invoices that are not in the last 6 months or next 3 months and sort by date
 */
function filterAndSortInvoices(invoices: Invoice[]): Invoice[] {
    const prevSixMonthsCutoff = makeMonthCutoff(new Date(), -6);
    const nextThreeMonthCutoff = makeMonthCutoff(new Date(), 4);

    return invoices.filter((inv) => {
        const invDate = new Date(`${inv.u_invoice_date}T12:00:00`);
        return prevSixMonthsCutoff < invDate && nextThreeMonthCutoff > invDate;
    }).sort((a, b) => {
        const aDate = new Date(`${a.u_invoice_date}T12:00:00`);
        const bDate = new Date(`${b.u_invoice_date}T12:00:00`);
        return aDate > bDate ? -1 : 1;
    });
}