import { Box, Button, Stack, Typography } from "@mui/material";
import InheritableButtonBase from "src/components/InheritableButtonBase";
import { CompanyOption } from "src/types";
import CompanyBadges from "../Companies/About/CompanyBadges";
import { useUser } from "src/hooks/useUser";
import { useCompanyList } from "src/hooks/useCompanyList";
import { useNavigate } from "react-router-dom";
import useRegionPath from "src/hooks/useRegionPath";
import ErrorComponent from "src/components/Error";


import { grey } from '@mui/material/colors'; 
import { styled } from '@mui/material';
import { keyframes } from '@emotion/react';
import HomePgComponent from "./HomePgComponent";
import isTouchScreen from "src/hooks/isTouchScreen";




// This file can be deleted after the homepage dashboard goes into use.  


export default function OriginalMyCompanies() {
    const snUser = useUser();
    const companyList = useCompanyList();

    const navigate = useNavigate();
    const region  = useRegionPath(); 

    const MyCompaniesComponent = (companyList instanceof Error || snUser instanceof Error)
        ? <ErrorComponent />
        : !snUser || !companyList
            ? <MyCompaniesSkeleton count={5} />
            : <MyCompaniesDisplay
                companies={companyList}
                userSysId={snUser.sys_id}
                nameClickFn={ (comp) => navigate(`/${region}/companies/${comp.u_company_id}/${comp.sys_id}`) }
                // companyCtLimit={5}
                viewAllClickFn={() => navigate(`/${region}/my-companies`)}
                isTouchScreen={isTouchScreen()}
            />

    return (
        <HomePgComponent
            header="My Companies"
            subheader="Companies where you are the Strategic Lead, Security Lead, Account Owner, Client Success Manager or Enterprise Lead."
            body={MyCompaniesComponent}
        />
    );
}


interface MyCompaniesDisplayProps {
    companies: CompanyOption[] | Error,
    userSysId: string,
    /** callback to execute on click of a listed company */
    nameClickFn: (company: CompanyOption) => void,
    companyCtLimit?: number,
    /** 
     * callback to execute on click of the "view all" button if the user
     * provides a companyCtLimit and the number of companies exceeds that limit
     * */
    viewAllClickFn?: () => void
    isTouchScreen: boolean
}


function MyCompaniesDisplay(props: MyCompaniesDisplayProps) {
    const companies = props.companies;
    const userSysId = props.userSysId;
    const nameClickFn = props.nameClickFn;
    const companyCtLimit = props.companyCtLimit;
    const viewAllClickFn = props.viewAllClickFn || (() => { });

    if (companies instanceof Error) return <Box><i>An error occurred loading companies.</i></Box>;
    const userCompanies = companies.filter(el => {
        return userSysId !== "" && (
            el.u_spc.id === userSysId || el.u_ae.id === userSysId || el.u_cem.id === userSysId || el.u_security_lead.id === userSysId || el.u_enterprise_lead.id === userSysId
        );
    });

    userCompanies.sort((a, b) => {
        return a.u_status === "At Risk" && b.u_status !== "At Risk" ? -1 : 1;
    });

    const additionalCss = props.isTouchScreen ? {} : {'&:hover': { backgroundColor: '#EAEAEA', borderRadius: '3px' }}

    const companyDomElms = userCompanies.map((company, ind) => {
        if (companyCtLimit && ind + 1 > companyCtLimit) return null
        return (
            <InheritableButtonBase 
                sx={{width: '100%', borderRadius: '4px', paddingX: '5px', paddingY: '4px', ...additionalCss}}
                key={ind}
            >
                <Box
                    sx={{ cursor: 'pointer', width: '100%' }}
                    onClick={() => nameClickFn(company)}
                >
                    <Stack direction="row" spacing={1} alignItems="center" >
                        <Typography>{company.name}</Typography>
                        <Box id="EmptyBoxToPushChipsToRight" sx={{ flexGrow: 1 }}></Box>
                        <CompanyBadges company={company} justifyContentSetting='flex-end' />
                    </Stack>
                </Box>
            </InheritableButtonBase>
        );
    });


    return (
        <>
            {!userCompanies.length
                ? <Box>No results.</Box>
                : <Box>
                    {companyDomElms}
                    {companyCtLimit && userCompanies.length > companyCtLimit
                        ? <Stack alignItems="center">
                            <Button
                                sx={{ marginTop: "10px" }}
                                variant="contained"
                                onClick={() => viewAllClickFn()}
                                data-testid="moreCompaniesTest"
                            >
                                {`Showing ${companyCtLimit} ${companyCtLimit === 1 ? "Company" : "Companies"}.  View All ${userCompanies.length}`}
                            </Button>
                        </Stack>
                        : <></>
                    }
                </Box>
            }
        </>
    );
}



const myKeyframe = keyframes`
  0%  {background-color: #E4E6EB;}
  50%  {background-color: #fafafa;}
  100%  {background-color: #E4E6EB;}
  `;


const FlashingDiv = styled('div')({
    backgroundColor: grey[200],
    animation: `${myKeyframe} 3s infinite ease`
  });

export type MyCompaniesSkeletonProps = {
    count: number
}


function MyCompaniesSkeleton(props: MyCompaniesSkeletonProps){
    const count = props.count; 
    //@ts-ignore
    const listOfSkeletons = Array(count).fill().map((x, index) => <IndSkeleton key={String(index)}/>)
    return (
        <>{listOfSkeletons}</>
    )
}

function IndSkeleton() {

    return (
        <Box sx={{marginX: 2}} data-testid="skeleton-id-for-test">
            <Stack sx={{width: '100%', marginTop: 1}} direction="row" justifyContent="center" alignItems="center">
                <FlashingDiv sx={{fontSize: '2rem', width: '20rem', borderRadius: 1}}>
                    &nbsp;
                </FlashingDiv>
                {/** Empty div with flexGrow to fill blank space */}
                <Box sx={{flexGrow: 2}}></Box>
                <FlashingDiv sx={{ fontSize: '2.5rem', borderRadius: 4, width: '6rem'}}>
                    &nbsp;
                </FlashingDiv>
            </Stack>
        </Box>
    )   
}