import { Stack, Box } from '@mui/material';
import { Company, CompanyOption } from '../../../types';
import CompanyBadges from '../About/CompanyBadges';

type CompanyHeaderProps = {
    company: Company | CompanyOption; 
};

export default function CompanyHeader(props: CompanyHeaderProps) {
    const company = props.company; 

    return (
        <Box>
            <Stack direction="row" justifyContent="align-start" alignItems="flex-end" sx={{ maxWidth: "100%", flexWrap: 'wrap', margin: 1}}>
                <Box sx={{fontSize: "3rem", fontWeight: "bold", marginRight: '10px' }}>{company.name}</Box>
                <Box sx={{marginBottom: '5px'}}>
                    {
                        <CompanyBadges company={company} justifyContentSetting='flex-start'  />
                    }          
                </Box>
            </Stack>
        </Box>
    );
}