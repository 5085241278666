import { generatePath } from "react-router-dom";

import ErrorPage from "../pages/Error";
import Root from "../pages/Root/";
import Home from "../pages/Home/";
import CompanyPage from "../pages/Companies/";

import Invoices from "../pages/Companies/Invoices/";
import Orders from "../pages/Companies/Orders/";
import Contracts from "../pages/Companies/Contracts/";
import Services from "src/pages/Companies/Services/";
import Publications from "src/pages/Companies/Publications/";

import RecentOrders from "../pages/RecentOrders";
import CompanyToInvoiceRedirect from "../pages/CompanyRedirect";
import AdvancedSearch from "../pages/AdvancedSearch/";
import ProductCatalog from "src/pages/ProductCatalog";

import type { RouteObject } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { REGIONS } from "src/hooks/useRegion";
import DataCenters from "src/pages/DataCenters/";

export const COMPANY_PAGE_ID = 'company-page';

export function createConfig(): RouteObject[] {
    const config: RouteObject[] = [
        {
            path: "",
            element: <Root />,
            errorElement: <ErrorPage />,
            children: REGIONS.map((region) => ({
                path: generatePath(":region", { region }),
                element: <Outlet />,
                children: [
                    {
                        path: "",
                        element: <Home />,
                        errorElement: <ErrorPage />,
                    },
                    {
                        id: `${COMPANY_PAGE_ID}-${region}`,
                        // u_company_id added for cosmetic purposes; sys_id used in data requests;
                        path: "companies/:u_company_id/:sys_id",
                        element: <CompanyPage />,
                        errorElement: <ErrorPage />,
                        children: [
                            {
                                path: "",
                                element: <CompanyToInvoiceRedirect />,
                                errorElement: <ErrorPage />,
                            },
                            {
                                path: "invoices",
                                element: <Invoices />,
                                errorElement: <ErrorPage />,
                            },
                            {
                                path: "orders",
                                element: <Orders />,
                                errorElement: <ErrorPage />,
                            },
                            {
                                path: "contracts",
                                element: <Contracts />,
                                errorElement: <ErrorPage />,
                            },
                            {
                                path: "communications", 
                                element: <Publications />, 
                                errorElement: <ErrorPage />,
                            },
                            {
                                path: "services",
                                element: <Services />,
                                errorElement: <ErrorPage />,
                            },
                        ],
                    },
                    {
                        path: "recent-wins",
                        element: <RecentOrders showAllResults={true} />,
                        errorElement: <ErrorPage />,
                    },
                    {
                        path: "advanced-search",
                        element: <AdvancedSearch />,
                    },
                    {
                        path: "product-catalog",
                        element: <ProductCatalog />,
                        errorElement: <ErrorPage />,
                    },
                    {
                        path: "data-centers", 
                        element: <DataCenters />, 
                        errorElement: <ErrorPage />,
                    }
                ],
            })),
        },
    ];

    return config;
}
