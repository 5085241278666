import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Box from "@mui/material/Box";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import CompanySearchBar from "./CompanySearchBar/CompanySearchBar";
import SiteMenuDrawer from "./SiteMenuDrawer/SiteMenuDrawer";
import UserInfoAndActionsIcons from "./UserInfoAndActionsIcons/UserInfoAndActionsIcons";
import MoreMenu from "./UserInfoAndActionsIcons/MoreMenu";
import MobileMenu from "./UserInfoAndActionsIcons/MobileMenu";

import type { CompanyOption } from "../../../types";

import insightLogoImg from "src/assets/insight-logo_100x35.png";
import insightIconImg from "src/assets/insight-icon_47x47.png";
import { SxProps } from "@mui/material";
import { Theme } from "@mui/material";
import RegionSwitch from "./RegionSwitch";
import { useQueryClient } from "@tanstack/react-query";
import { useCompanyList } from "src/hooks/useCompanyList";
import { useRegionAssertively } from "src/hooks/useRegion";
import { useCompanyIdFromPath } from "src/hooks/useCompanyIdFromPath";

interface Props {
    onCompanyChange: (val: CompanyOption) => void;
    onTitleClick: () => void;
    navBarValueCtrl: string; 
    setNavBarValueCtrl: React.Dispatch<React.SetStateAction<string>>;
    onSeeMore: () => void;
}

const NavBar = (props: Props) => {
    const queryClient = useQueryClient();

    //CompanySearchBar
    const companies = useCompanyList();
    const region = useRegionAssertively();
    const { navBarValueCtrl, setNavBarValueCtrl, onSeeMore } = props;
    const location = useLocation();

    // Doc Title Update
    const companyId = useCompanyIdFromPath();  
    useEffect(() => {
        const companyName = companies && !(companies instanceof Error) && companyId ? companies.find(c => c.sys_id === companyId)?.name : undefined; 
        const pathNameElms = location.pathname.split("/");
        const pageName = pathNameElms[2] || "";
        const companyPage = pathNameElms[5] || ""; 
        docTitleUpdate(companyName || "", pageName, companyPage); 
    }, [location, companies, companyId])



    //UserInfoAndActionsIcons
    const menuId = "primary-search-account-menu";
    const mobileMenuId = "primary-search-account-menu-mobile";

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
        useState<null | HTMLElement>(null);

    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const navigate = useNavigate(); 
    const usChecked = region === 'us'
    const handleRegionSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log("region has changed, clearing queries: ", region);
        queryClient.cancelQueries();
        queryClient.clear();
        if (event.target.checked) {
            navigate("/us/");
        } else {
            navigate("/eu/");
        }
    };


    //SiteMenuDrawer
    const [menuDrawerState, setMenuDrawerState] = useState(false);

    const theme = useTheme();
    const screenSizeSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const logoWrapperSxLarge: SxProps<Theme> = (theme) => ({
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(2),
        display: "flex",
        color: "#F3F3F6",
        "&:hover": {
            color: "#C7C8D8",
        },
        cursor: "pointer"
    });
    
    const logoWrapperSxSmall: SxProps<Theme> = (theme) => ({
        marginRight: theme.spacing(1),
        display: "flex",
        color: "#F3F3F6",
        "&:hover": {
            color: "#C7C8D8",
        },
        cursor: "pointer",
    });

    return (
        <>
            <Box sx={{ width: 1 }}>
                <AppBar
                    position="relative"
                    sx={{
                        backgroundColor: region === 'us' ? "#293D74" : "#293D54", 
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                >
                    <Toolbar sx={{
                        '&.MuiToolbar-root': {
                            [theme.breakpoints.down('md')]: {
                                pr: 1.5
                            },
                            [theme.breakpoints.down('sm')]: {
                                pr: 1
                            },
                        }
                    }}>
                        <IconButton
                            edge="start"
                            sx={{ mr: 0.7, paddingRight: 0 }}
                            color="inherit"
                            aria-label="open drawer"
                            onClick={() => setMenuDrawerState(!menuDrawerState)}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Box
                            role="link"
                            component="a"
                            onClick={props.onTitleClick}
                            sx={screenSizeSmall ? logoWrapperSxSmall : logoWrapperSxLarge}
                        >
                            <img
                                    src={screenSizeSmall ? insightIconImg : insightLogoImg}
                                    className="App-logo"
                                    alt="logo"
                                />
                        </Box>
                        <CompanySearchBar
                            onCompanyChange={props.onCompanyChange}
                            companyList={companies}
                            screenSizeSmall={screenSizeSmall}
                            navBarValueCtrl={navBarValueCtrl}
                            setNavBarValueCtrl={setNavBarValueCtrl}
                            onSeeMore={onSeeMore}
                        ></CompanySearchBar>
                        <Box sx={{ [theme.breakpoints.up('md')]: {
                                flexGrow: 1
                            },
                            [theme.breakpoints.down('md')]: {
                                flexGrow: 0
                            },}} 
                        />
                        <RegionSwitch handleChangeFn={handleRegionSwitchChange} checked={usChecked} />
                        <UserInfoAndActionsIcons
                            menuId={menuId}
                            mobileMenuId={mobileMenuId}
                            handleProfileMenuOpen={handleProfileMenuOpen}
                            handleMobileMenuOpen={handleMobileMenuOpen}
                        ></UserInfoAndActionsIcons>
                    </Toolbar>
                </AppBar>
                <MobileMenu
                    mobileMenuId={mobileMenuId}
                    mobileMoreAnchorEl={mobileMoreAnchorEl}
                    handleProfileMenuOpen={handleProfileMenuOpen}
                    handleMobileMenuClose={handleMobileMenuClose}
                ></MobileMenu>
                <MoreMenu
                    menuId={menuId}
                    anchorEl={anchorEl}
                    handleMenuClose={handleMenuClose}
                ></MoreMenu>
                <SiteMenuDrawer
                    menuDrawerState={menuDrawerState}
                    setMenuDrawerState={setMenuDrawerState}
                ></SiteMenuDrawer>
            </Box>
        </>
    );
};

export default NavBar;





function docTitleUpdate(companyName: string, pageName: string, companyPage: string) {
    const newDocTitle = !pageName ? "Insight" : 
            pageName === 'my-companies' ? "My Companies - Insight" :
            pageName === 'advanced-search' ? "Advanced Search - Insight" :
            pageName === 'recent-wins' ? "Recent Wins - Insight" :
            pageName === 'product-catalog' ? "Product Catalog - Insight" :
            pageName === 'companies' && companyName && companyPage === 'invoices' ? `${companyName} - Invoices - Insight` :
            pageName === 'companies' && companyName && companyPage === 'orders' ? `${companyName} - Orders - Insight` :
            pageName === 'companies' && companyName && companyPage === 'contracts' ? `${companyName} - Contracts - Insight` :
            pageName === 'companies' && companyName && companyPage === 'services' ? `${companyName} - Services - Insight` :
            "Insight";
    document.title = newDocTitle;
}