import React, { useRef, useEffect, useState } from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { green, grey } from '@mui/material/colors';
import { getMonthName } from '../../../util/timeUtils';

import Tooltip from '@mui/material/Tooltip';
import ErrorIcon from '@mui/icons-material/Error';
import InvoiceOrderDetailsSkeleton from '../../../components/Skeleton/InvoiceOrderDetailsSkeleton';

import InvoicesTrend from 'src/pages/Companies/Invoices/InvoicesTrend';
import { Scroller, ScrollerElm } from 'src/components/Scroller';

import type { Invoice, InvoiceDetails } from '../../../types';
import { useInvoiceDetails } from 'src/hooks/useInvoices';
import { useProductIdToFamilyMap } from 'src/hooks/useProductCatalog';
import assertively from 'src/util/assertively';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Skeleton, alpha, useTheme } from '@mui/material';

const MRR_TYPES = ['MRR', 'MRRP']; 

interface InvoicesProps {
    invoices: Invoice[],
    u_numberOpen?: string,
    onChangeFn: (invoiceNumber: string) => void,
    isTouchScreen: boolean,
    scrollerContainerSize: number, 
    companySysId?: string, 
    openInvoices: string[]
}

export default function InvoicesDisplay({ invoices, u_numberOpen, onChangeFn, isTouchScreen, scrollerContainerSize, companySysId, openInvoices }: InvoicesProps) {
    const [invoiceSummarized, setInvoiceSummarized] = useState(""); 

    const accordionSx = {
        boxShadow: 0.5,
        border: 1,
        borderColor: '#e6e6e6',
        borderRadius: 1,
        margin: 1,
        '&:before': { display: 'none' },
        backgroundColor: grey[50]
    };
    const additionalSummarySx = isTouchScreen
        ? {}
        : { '&:hover': { backgroundColor: '#EAEAEA', borderRadius: '3px' } };

    let printMonth = '';
    const monthTotals: {[index: string]: {total:number, mrr:number}} = {}
    invoices.forEach(invoice => {
        const invDate: Date = new Date(`${invoice.u_invoice_date}T12:00:00`);
        const invMonth = `${getMonthName(invDate.getMonth())} ${invDate.getFullYear()}`;
        if(!monthTotals[invMonth]) monthTotals[invMonth] = {total:0, mrr:0}; 
        monthTotals[invMonth].total += Number.parseFloat(invoice.u_invoice_total)
        if(MRR_TYPES.includes(invoice.u_invoice_type.toUpperCase())){
            monthTotals[invMonth].mrr += Number.parseFloat(invoice.u_invoice_total)
        }
    })

    let scrollToOpenRef = useRef<HTMLDivElement>(null);

    // only control scroll on first render
    useEffect(() => {
        scrollToOpenRef.current?.scrollIntoView();
    }, [])

    return (
        !invoices.length ? <Box sx={{ fontSize: '1.8rem', margin: 2 }}>No Invoices Found</Box> :
            <Box>
                <Scroller containerSize={scrollerContainerSize} elementMinWidth={250} key={companySysId}>
                    <ScrollerElm cardContentSx={{ backgroundColor: grey[50] }}>
                        <InvoicesTrend invoices={invoices} referenceDate={new Date()} trendType={"last"} />
                    </ScrollerElm>
                    <ScrollerElm cardContentSx={{ backgroundColor: grey[50] }}>
                        <InvoicesTrend invoices={invoices} referenceDate={new Date()} trendType={"last3"} />
                    </ScrollerElm>
                    <ScrollerElm cardContentSx={{ backgroundColor: grey[50] }}>
                        <InvoicesTrend invoices={invoices} referenceDate={new Date()} trendType={"last6"} />
                    </ScrollerElm>
                </Scroller>
                {
                    invoices.map((invoice, index) => {
                        const _invoiceTotal = Number.parseFloat(invoice.u_invoice_total_local);
                        const invoiceTotal = Number.isNaN(_invoiceTotal) ? "—" : `${_invoiceTotal.toLocaleString()}`;

                        const invDate: Date = new Date(`${invoice.u_invoice_date}T12:00:00`);
                        const invMonth = `${getMonthName(invDate.getMonth())} ${invDate.getFullYear()}`;
                        const includeMonth = printMonth !== invMonth;
                        if (includeMonth) printMonth = invMonth;
                        const mthTotal = monthTotals[printMonth].total; 
                        const mthMrr = monthTotals[printMonth].mrr; 
                        const pctStr = mthMrr === 0 || mthTotal === 0 ? "" : `(${Math.round(mthMrr * 100 / mthTotal)}%)`;  

                        const monthSx = {
                            textAlign: 'left',
                            color: '#808080',
                            fontSize: "small",
                            marginLeft: 1.5,
                            marginTop: (index > 0) ? 3 : 1,
                            marginBottom: 2, 
                            cursor: 'pointer'
                        };

                        return (
                            <React.Fragment key={invoice.u_number}>
                                {includeMonth && 
                                    <Tooltip
                                        title={
                                            <span style={{ whiteSpace: 'pre-line' }}>
                                                {`${printMonth}\n\nTotal: $${mthTotal.toLocaleString()}\nMRR: $${mthMrr.toLocaleString()} ${pctStr}`}
                                            </span>
                                        } placement="top-start" > 
                                        <Box sx={monthSx}>{printMonth}</Box>
                                    </Tooltip>
                                }
                                <Accordion
                                    ref={invoice.u_number === u_numberOpen ? scrollToOpenRef : null}
                                    sx={accordionSx}
                                    expanded={openInvoices.includes(invoice.u_number)}
                                    data-testid={`invoiceKey-${invMonth}-${index}`}
                                    onChange={(e, expanded) => onChangeFn(invoice.u_number)}
                                    TransitionProps={{ unmountOnExit: true }}
                                >
                                    <AccordionSummary
                                        sx={{
                                            width: '100%',
                                            paddingX: '5px',
                                            paddingY: '6px',
                                            borderRadius: '4px',
                                            '& .MuiAccordionSummary-content': {
                                                margin: '0px'
                                            },
                                            ...additionalSummarySx
                                        }}
                                        disableRipple={false}
                                    >
                                        <Stack sx={{ width: '100%', px: 1 }}>
                                            <Stack sx={{ width: '100%' }} flexDirection="row" justifyContent="space-between">
                                                <Box sx={{ fontSize: '1.2rem' }}>
                                                    {invDate.toLocaleDateString()}
                                                </Box>
                                                <Box sx={{ fontSize: '1.2rem' }}>
                                                    {invoice.u_invoice_total_local_code}
                                                </Box>
                                            </Stack>
                                            <Stack direction="row" alignItems="center" sx={{ width: '100%' }}>
                                                <Box sx={{ fontSize: '1.8rem', fontWeight: 'bold' }}>
                                                    {invoice.u_number}
                                                </Box>
                                                {
                                                    MRR_TYPES.includes(invoice.u_invoice_type.toUpperCase()) ?
                                                        <Chip sx={{ marginLeft: '8px', marginRight: "4px", fontSize: "1.4rem", fontWeight: 'bold', flexShrink: 2, minWidth: '0px', overflow: 'hidden', backgroundColor: green[100], color: green[800] }} label={invoice.u_invoice_type.toUpperCase()} color="success" size="small" /> :
                                                        <Chip sx={{ marginLeft: '8px', marginRight: "4px", fontSize: "1.4rem", fontWeight: 'bold', flexShrink: 2, minWidth: '0px', overflow: 'hidden' }} label={invoice.u_invoice_type.toUpperCase()} size="small" />
                                                }
                                                {/** Empty div with flexGrow to fill blank space */}
                                                <Box sx={{ flexGrow: 2 }}></Box>
                                                <Box sx={{ fontSize: '1.8rem', fontWeight: 'bold' }}>
                                                    {invoiceTotal}
                                                </Box>
                                            </Stack>
                                        </Stack>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ padding: '0px', mt: 1 }}>
                                        <ExpandedInvoice
                                            invoice={invoice}
                                            invDate={invDate}
                                            invoiceTotal={invoiceTotal}
                                            setInvoiceSummarized={setInvoiceSummarized}
                                        />
                                    </AccordionDetails>
                                </Accordion>
                            </React.Fragment>
                        )
                    })
                }
                <InvoiceSummaryDialog invoiceSummarized={invoiceSummarized} setInvoiceSummarized={setInvoiceSummarized} invoices={invoices} />
            </Box>
    );
}

interface InvoiceSummaryDialogProps {
    invoiceSummarized: string, 
    setInvoiceSummarized: (invoiceNumber: string) => void,
    invoices: Invoice[],
}

function InvoiceSummaryDialog({ invoiceSummarized, setInvoiceSummarized, invoices }: InvoiceSummaryDialogProps) {

    return (
        <>
            <Dialog
            fullWidth={true}
            maxWidth="lg"
            open={!!invoiceSummarized}
            onClose={() => setInvoiceSummarized('')}
            >
            <DialogTitle>{`Invoice #${invoiceSummarized} Summary`}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Box>
                        <AdditionSummary invoiceSummarized={invoiceSummarized} invoices={invoices} />
                    </Box>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setInvoiceSummarized("")}>Close</Button>
            </DialogActions>
            </Dialog>
        </>
    )

}

interface AdditionsSummaryProps {
    invoiceSummarized: string,
    invoices: Invoice[], 
}

const msFor35Days = 35 * 24 * 60 * 60 * 1000;

function AdditionSummary({ invoiceSummarized, invoices }: AdditionsSummaryProps) {
    const theme = useTheme(); 
    const invoice = invoices.find(inv => inv.u_number === invoiceSummarized); 

    const previousMonthInvoice = invoices.find(inv => {
        const msDif =  !invoice ? 0 : new Date(invoice.u_invoice_date).valueOf() - new Date(inv.u_invoice_date).valueOf(); 
        // looking for last month's invoice from same billing location
        return inv.u_invoice_type === invoice?.u_invoice_type &&
            inv.u_billing_location.display === invoice.u_billing_location.display && 
            msDif > 0 && msDif < msFor35Days;

    })
    const prevInvoiceDetails = assertively(useInvoiceDetails(previousMonthInvoice?.sys_id || ''));
    const currentInvoiceDetails = assertively(useInvoiceDetails(invoice?.sys_id || ''));
    const productIdToFamilyMap = assertively(useProductIdToFamilyMap());

    const prevInvoiceDetailsWithProductFamily = !prevInvoiceDetails || !productIdToFamilyMap ? [] : 
        prevInvoiceDetails.map(el => {
            return {
                ...el, 
                u_product_family: productIdToFamilyMap[el.u_product_id] || "Unknown"
            }
        }); 
    const currentInvoiceDetailsWithProductFamily = !currentInvoiceDetails || !productIdToFamilyMap ? [] :
        currentInvoiceDetails.map(el => {
            return {
                ...el, 
                u_product_family: productIdToFamilyMap[el.u_product_id] || "Unknown"
            }
        });

    type invoiceWithPF = InvoiceDetails & {u_product_family: string};
    let curCtr: Record<string, boolean> = {}; 
    currentInvoiceDetailsWithProductFamily.forEach((inv) => curCtr[inv.u_billed_amount_local_code] = true); 
    prevInvoiceDetailsWithProductFamily.forEach((inv) => curCtr[inv.u_billed_amount_local_code] = true); 
    const useLocalCurrency = Object.keys(curCtr).length === 1; 
    const currencyToDisplay = useLocalCurrency ? Object.keys(curCtr)[0] : "USD";
    let monthDif = 0; 
    prevInvoiceDetailsWithProductFamily.forEach(inv => {
        monthDif -= Number.parseFloat(useLocalCurrency ? inv.u_billed_amount_local : inv.u_billed_amount);
    })
    currentInvoiceDetailsWithProductFamily.forEach(inv => {
        monthDif += Number.parseFloat(useLocalCurrency ? inv.u_billed_amount_local : inv.u_billed_amount);
    })

    const prevInvoiceFamilyTotals = prevInvoiceDetailsWithProductFamily.reduce((acc: Record<string, number>, el: invoiceWithPF) => {
        if(!acc[el.u_product_family]) acc[el.u_product_family] = 0; 
        acc[el.u_product_family] += Number.parseFloat(useLocalCurrency ? el.u_billed_amount_local : el.u_billed_amount); 
        return acc; 
    }, {})
    const curInvoiceFamilyTotals = currentInvoiceDetailsWithProductFamily.reduce((acc: Record<string, number>, el: invoiceWithPF) => {
        if(!acc[el.u_product_family]) acc[el.u_product_family] = 0; 
        acc[el.u_product_family] += Number.parseFloat(useLocalCurrency ? el.u_billed_amount_local : el.u_billed_amount); 
        return acc; 
    }, {})

    const categories = [
        "Security", "Cloud", "Managed Infrastructure", "Disaster Recovery", 
        "Backup", "Microsoft 365", "Compliance", "IT Automation", 
        "Acct Mgmt", "End User Support", "Legacy Product", "Discounts"
    ]; 

    const inspection = currentInvoiceDetailsWithProductFamily.filter(el => !categories.includes(el.u_product_family)); 
    if(inspection.length) console.log("Invoice line items not included in totals: : ", inspection); 

    if(! MRR_TYPES.includes(invoice?.u_invoice_type.toUpperCase() || '')) return <></>
    if(currentInvoiceDetails && productIdToFamilyMap && !currentInvoiceDetailsWithProductFamily.length){
        return(
            <Box sx={{p:1}}>No invoice details available.</Box>
        )         
    }; 
    const loadingDif = !productIdToFamilyMap || (!prevInvoiceDetails && previousMonthInvoice?.sys_id) || !currentInvoiceDetailsWithProductFamily.length; 

    return (
        <Box sx={{p:1}}>
            <Grid container>
                <Grid item xs={12}>
                    <Box sx={{backgroundColor: theme.palette.info.main, color: theme.palette.info.contrastText, 
                        pb:1, px:1, display: 'flex', justifyContent: 'center', textAlign: 'center'}}>
                        Services Summary
                    </Box>
                </Grid>
                {
                    categories.map(cat => {
                        const bgColor = !currentInvoiceDetailsWithProductFamily.length || curInvoiceFamilyTotals[cat] ? theme.palette.primary.main : alpha(theme.palette.primary.main, 0.2);
                        const fontColor = !currentInvoiceDetailsWithProductFamily.length || curInvoiceFamilyTotals[cat] ? theme.palette.primary.contrastText : alpha(theme.palette.primary.contrastText, 0.4);
                        const valueFontColor = curInvoiceFamilyTotals[cat] ? "#000" : alpha("#000", 0.2);

                        return (
                            <Grid item xs={6} md={3} key={`grid-for-${cat}`}>
                                <Box sx={{display: 'flex', justifyContent: 'center', backgroundColor: bgColor, px:.5, pb:1, textAlign: 'center'}}>
                                    <Typography sx={{color: fontColor}}>{cat}</Typography>
                                </Box>
                                <Box sx={{display: 'flex', justifyContent: 'center', p:.5, backgroundColor: '#FFFFFF', textAlign: 'center'}}>
                                    <Typography sx={{color: valueFontColor}}>{
                                        !currentInvoiceDetailsWithProductFamily.length ? 
                                            <Skeleton variant="text" width={40} /> :
                                            `${currencyToDisplay} ` + 
                                            `${curInvoiceFamilyTotals[cat] ? (Math.round(curInvoiceFamilyTotals[cat]*100)/100).toFixed(2) : '0.00'}`
                                    }</Typography>
                                </Box>
                            </Grid>
                        )
                    })
                }

                <Grid item xs={12} sx={{mt:3}}>
                    <Box sx={{backgroundColor: theme.palette.info.main, color: theme.palette.info.contrastText, 
                        pb:1, px:1, display: 'flex', justifyContent: 'center', textAlign: 'center'}}>
                        {`Additions and Removals ${!prevInvoiceDetailsWithProductFamily.length ? "" : `${currencyToDisplay} ${monthDif > -1 && monthDif < 1 ? "0.00" : monthDif.toFixed(2)}`}`}
                    </Box>
                </Grid>

                {
                    !previousMonthInvoice?.sys_id || (prevInvoiceDetails && productIdToFamilyMap && !prevInvoiceDetailsWithProductFamily.length) ? 
                        <Box sx={{p:1}}>Previous month invoice details unavailable.</Box> : 
                        <>
                            {
                                categories.map(cat => {
                                    const invDif = (curInvoiceFamilyTotals[cat] || 0) - (prevInvoiceFamilyTotals[cat] || 0)

                                    const bgColor = !prevInvoiceDetailsWithProductFamily.length || 
                                        (invDif && (invDif < -1 || invDif > 1))  ? theme.palette.primary.main : alpha(theme.palette.primary.main, 0.2);
                                    const fontColor = !prevInvoiceDetailsWithProductFamily.length || 
                                        (invDif && (invDif < -1 || invDif > 1)) ? theme.palette.primary.contrastText : alpha(theme.palette.primary.contrastText, 0.4);
                                    const valueFontColor = invDif === 0 || Number.isNaN(invDif) || (invDif < 1 && invDif > -1) ? alpha("#000", 0.2) : 
                                        invDif > 0 ? theme.palette.success.dark : theme.palette.error.dark;

                                    return (
                                        <Grid item xs={6} md={3} key={`grid-for-${cat}`}>
                                            <Box sx={{display: 'flex', justifyContent: 'center', backgroundColor: bgColor, px:.5, pb:1, textAlign: 'center'}}>
                                                <Typography sx={{color: fontColor}}>{cat}</Typography>
                                            </Box>
                                            <Box sx={{display: 'flex', justifyContent: 'center', p:.5, backgroundColor: '#FFFFFF', textAlign: 'center'}}>
                                                <Typography sx={{color: valueFontColor, fontWeight: 'bold'}}>{
                                                    loadingDif ? 
                                                        <Skeleton variant="text" width={40} /> :
                                                        `${currencyToDisplay} ` + 
                                                        `${invDif ? (Math.round(invDif*100)/100).toFixed(2) : '0.00'}`
                                                }</Typography>
                                            </Box>
                                        </Grid>
                                    )
                                })
                            }
                        </>
                }


            </Grid>

        </Box>
    )
}


interface IExpandedInvoiceProps {
    invoice: Invoice,
    invDate: Date,
    invoiceTotal: string,
    setInvoiceSummarized: (invoiceNumber: string) => void
}


function ExpandedInvoice({ invoice, invDate, invoiceTotal, setInvoiceSummarized }: IExpandedInvoiceProps) {
    const invoiceDetails = useInvoiceDetails(invoice.sys_id);

    const showSummarize = MRR_TYPES.includes(invoice.u_invoice_type.toUpperCase()); 

    return (
        <Grid container sx={{ backgroundColor: '#EAEAEF', borderRadius: '4px' }}>
            <Grid item xs={0} sm={1}></Grid>
            <Grid item xs={12} sm={11}>
                <Grid container>
                    <Grid item xs={12} sm={6} md={4}>
                        <KeyValueDisplay label="Date" value={invDate.toLocaleDateString()} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <KeyValueDisplay label="Invoice #" value={invoice.u_number} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <KeyValueDisplay label="Type" value={invoice.u_invoice_type.toUpperCase()} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <KeyValueDisplay label="Billing Location" value={invoice.u_billing_location.display} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <KeyValueDisplay label="Total" value={`${invoiceTotal} ${invoice.u_invoice_total_local_code}`} />
                    </Grid>
                    {/* <Grid item xs={12} sm={6} md={4}>
                        <KeyValueDisplay label={<br/>} value={<a href={invoice.u_pdf_link}>PDF Link</a>}/>
                    </Grid> */}
                    <Grid item xs={12} sm={6} md={4}>
                        {
                            showSummarize && 
                                <Button onClick={() => setInvoiceSummarized(invoice.u_number)} variant="contained" size="small" sx={{my:1, ml:.5}}>Summarize</Button>
                        }
                    </Grid>
                </Grid>
                {
                    (invoiceDetails instanceof Error) ?
                        <InvoiceDetailsError /> : 
                        ! invoiceDetails ?
                            <Grid container><InvoiceOrderDetailsSkeleton count={5} /></Grid>
                            : !invoiceDetails.length
                                ? <EmptyInvoiceDetails />
                                : <InvoiceDetailsList invoiceDetails={invoiceDetails} invoice={invoice}/>
                }
            </Grid>
        </Grid>
    );
}


function InvoiceDetailsList({ invoiceDetails, invoice }: { invoiceDetails: InvoiceDetails[], invoice: Invoice }) {
    const vertCellPad = "8px";
    const horzCellPad = "5px";

    return (
        <Grid container key={`${invoice.sys_id}-details-list`} >
            <Grid item xs={3} sx={{ paddingY: vertCellPad, paddingX: horzCellPad }}>
                Product ID
            </Grid>
            <Grid item xs={5} sx={{ paddingY: vertCellPad, paddingX: horzCellPad }}>
                Description
            </Grid>
            <Grid item xs={2} sx={{ paddingY: vertCellPad, paddingX: horzCellPad, textAlign: 'end' }}>
                Quantity
            </Grid>
            <Grid item xs={2} sx={{ paddingY: vertCellPad, paddingX: horzCellPad, textAlign: 'end' }}>
                Total Price
            </Grid>
            {
                invoiceDetails.map(invoiceItem => {
                    const _billedRate = Number.parseFloat(invoiceItem.u_billed_rate_local);
                    const billedRate = Number.isNaN(_billedRate) ? "—" : `${_billedRate.toLocaleString()}`;
                    const _billedAmount = Number.parseFloat(invoiceItem.u_billed_amount_local);
                    const billedAmount = Number.isNaN(_billedAmount) ? "—" : `${_billedAmount.toLocaleString()}`;

                    return (
                        <React.Fragment key={`${invoiceItem.sys_id}-invoice-detail-item`}>
                            <Grid item xs={3} sx={{ paddingY: vertCellPad, paddingX: horzCellPad, fontWeight: "medium", fontSize: '1.6rem' }}>
                                <Tooltip title={invoiceItem.u_product_id} placement="top-start">
                                    <Typography noWrap sx={{ fontWeight: "medium" }}>
                                        {invoiceItem.u_product_id}
                                    </Typography>
                                </Tooltip>
                            </Grid>
                            <Grid item xs={5} sx={{ paddingY: vertCellPad, paddingLeft: horzCellPad, paddingRight: "10px", fontSize: '1.6rem' }}>
                                <Tooltip title={invoiceItem.u_item} placement="top-start">
                                    <Typography noWrap sx={{ fontWeight: "medium" }}>
                                        {invoiceItem.u_item}
                                    </Typography>
                                </Tooltip>
                            </Grid>
                            <Grid item xs={2} sx={{ paddingY: vertCellPad, paddingX: horzCellPad, fontWeight: "medium", fontSize: '1.6rem', textAlign: 'end' }}>
                                <Tooltip title={`${invoiceItem.u_billed_quantity}`} placement="top-end">
                                    <Typography noWrap sx={{ fontWeight: "medium" }}>
                                        {invoiceItem.u_billed_quantity}
                                    </Typography>
                                </Tooltip>
                            </Grid>
                            <Grid item xs={2} sx={{ paddingY: vertCellPad, paddingX: horzCellPad, fontWeight: "medium", fontSize: '1.6rem', textAlign: 'end' }}>
                                <Tooltip title={`${invoiceItem.u_billed_quantity} x ${billedRate} ${invoiceItem.u_billed_rate_local_code}`} placement="top-end">
                                    <Typography noWrap sx={{ fontWeight: "medium" }}>
                                        {billedAmount} {invoiceItem.u_billed_amount_local_code}
                                    </Typography>
                                </Tooltip>
                            </Grid>
                        </React.Fragment>
                    )
                })
            }
        </Grid>
    );
}


function InvoiceDetailsError() {
    return (
        <Typography variant='h6' sx={{ margin: 2 }}>
            <ErrorIcon /> Error requesting invoice details. Reload page to try again.
        </Typography>
    );
}


function EmptyInvoiceDetails() {
    return (
        <Typography variant='body2' sx={{ margin: 2 }}>
            No invoice details found.
        </Typography>
    );
}


interface KeyValueDisplayProps {
    label: any,
    value: any
}

function KeyValueDisplay(props: KeyValueDisplayProps) {
    const label = props.label;
    const value = props.value;
    return (
        <Stack sx={{ margin: "6px" }}>
            <Box>
                <Typography >
                    {label}
                </Typography>
            </Box>
            <Box>
                <Typography sx={{ fontWeight: 'bold' }}>
                    {value}
                </Typography>
            </Box>
        </Stack>
    )
}
