import { ReactElement, useEffect, useMemo, useRef, useState } from "react";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { CompanyLocationsForMap, CompanyLocationsForMapIntermediate } from "src/types";
import { Box, ButtonBase, CircularProgress, FormControlLabel, FormGroup, Switch, TextField, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import { grey, blueGrey } from "@mui/material/colors";
import { useDataCenterLocations, useLocations, useMapsApiKey } from "src/hooks/useLocations";

const otherMapStates = (status: Status): ReactElement => {
    if (status === Status.FAILURE) return <ErrorComponent />;
    return <LoadingCompaonent />;
};


interface LocationsProps {
    accountSysId: string
}

export default function Locations({ accountSysId }: LocationsProps) {
    const locations = useLocations(accountSysId);
    const locationsWithColor = locations instanceof Error || !locations ? locations : locations.map(el => {
        return {
            ...el, 
            dataPointCategoryColor: "goldenrod" as ("green" | "grey" | "red" | "goldenrod")
        }
    })

    return <LocationsDisplay locations={locationsWithColor} />
}



function Circle({ color }: { color?: string  }) {
    return (
        <svg height="12" width="12" >
            <circle cx="6" cy="6" r="6" fill={color || "black"} />
        </svg>
    )
}



export function DataCenterLocations() {
    const THRIVE_ID = "0def01451b7d77c0589adac4bd4bcbd4" // Thrive sys_id;
    const dataCenterLocations = useDataCenterLocations(THRIVE_ID);
    const locations = dataCenterLocations instanceof Error || !dataCenterLocations ? dataCenterLocations : 
        dataCenterLocations.map(el => {

            const dataPointColor = el.operational_status === "Operational" ? "green" : 
                el.operational_status === "Retired" ? "grey" : "red";  

            return {
                ...el.location, 
                sys_id: el.sys_id,
                name: el.name, 
                operational_status: el.operational_status,
                subtitle_node: <Box sx={{ color: grey[700], textAlign: 'left',  mt: 1, display: 'flex', alignItems: 'center', }}>
                    <Box sx={{mr:1}}><Circle color={dataPointColor} /></Box>
                    <Box>Status: {el.operational_status}</Box>
                </Box>,
                dataPointCategoryColor: dataPointColor as ("green" | "grey" | "red")
            }
        }); 

    return <LocationsDisplay locations={locations} label="Data Center" showFilter={true} filterFn={(el, filterState) => filterState || el.operational_status !=="Retired"} filterLabel="Include Retired?"/>
}




interface LocationsDisplayProps {
    locations: CompanyLocationsForMapIntermediate, 
    label?: string, 
    showFilter?: boolean, 
    filterLabel?: string,
    filterFn?: (el: CompanyLocationsForMap, filterState: boolean) => boolean
}


export function LocationsDisplay({ locations, label, showFilter, filterFn, filterLabel }: LocationsDisplayProps) {
    const apiKey = useMapsApiKey(); 
    const largeScreen = useMediaQuery('(min-width:800px)');
    const [highlighted, setHighlighted] = useState("");
    const [search, setSearch] = useState("");
    const [filterState, setFilterState] = useState(false);
    const theme = useTheme(); 

    const changeHighlighted = (newid: string) => { highlighted === newid ? setHighlighted("") : setHighlighted(newid) }
    // memoized to prevent unnecessary MapComponent rerenders
    const filteredLocations = useMemo(() => {
        if(!locations || locations instanceof Error) return [] 
        var locationsFiltered = locations.filter(el => el.latitude && el.longitude);
        if(showFilter && filterFn) locationsFiltered = locationsFiltered.filter(el => filterFn(el, filterState));
        return locationsFiltered; 
    }, [locations, filterState, showFilter, filterFn]) 

    if (locations instanceof Error || apiKey instanceof Error) return <ErrorComponent />;
    if (!locations || !apiKey) return <LoadingCompaonent />;
    const hasExtraRow = locations.some(el => el.subtitle_node);

    return (
        <Box sx={{ height: '100%', width: '100%', display: 'flex', flexDirection: largeScreen ? 'row' : 'column' }} id='test1234'>
            {largeScreen &&
                <Box sx={{ width: '200px', height: '100%', display: 'flex', flexDirection: 'column' }}>
                    <Box sx={{ mb: 2, width: '100%' }}>
                        <Box sx={{ paddingRight: 2 }}>
                            <TextField id="location-search" label={!label ? "Location Search" : `${label} Search`} variant="standard" value={search} onChange={(e) => { setSearch(e.target.value) }} />
                        </Box>
                    </Box>
                    {
                        showFilter && 
                            <Box sx={{mb: 2, width: '100%'}}>
                                <FormGroup>
                                    <FormControlLabel 
                                        control={<Switch checked={filterState} onChange={() => setFilterState(!filterState)} />}
                                        label={<Box sx={{fontSize:12}}>{filterLabel}</Box>}
                                    />
                                </FormGroup>
                                
                            </Box>
                    }
                    <Box sx={{
                        height: '100%',
                        overflowY: 'auto',
                        paddingRight: 1,
                        mr:.5,
                        '&::-webkit-scrollbar': {
                            width: '10px'
                        },
                        '&::-webkit-scrollbar-track': {
                            backgroundColor: grey[200], 
                            borderRadius: '6px',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: theme.palette.secondary.light,
                            borderRadius: '6px',
                            border: `2px solid ${theme.palette.secondary.main}`      
                        }
                    }}>
                        <LocationListLg locations={filteredLocations} changeHighlighted={changeHighlighted} highlighted={highlighted} search={search} />
                    </Box>
                </Box>
            }
            <Wrapper 
                apiKey={apiKey} 
                render={otherMapStates}>
                {
                    !locations.length ? 
                        <Box sx={{ width: '100%', height: '100%', minHeight: '200px', textAlign: 'center', backgroundColor: grey[200], fontSize: 14, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {`No ${label?.toLowerCase() || "location"}s were returned from ServiceNow.`}
                        </Box> :
                    filteredLocations.length === 0 ?
                        <Box sx={{ width: '100%', height: '100%', minHeight: '200px', textAlign: 'center', backgroundColor: grey[200], fontSize: 14, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {`Company ${label?.toLowerCase() || "location"}s in ServiceNow are missing Lon / Lat data.`}
                        </Box> :
                        <MapComponent locations={filteredLocations} highlighted={highlighted} />
                }
            </Wrapper>
            {!largeScreen &&
                <Box sx={{ width: '100%', height: hasExtraRow ? '230px' : '170px', display: 'flex', flexDirection: 'column' }}>
                    <Box sx={{ my: .5, width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'end' }}>
                        <TextField fullWidth id="location-search" label={!label ? "Location Search" : `${label} Search`} variant="standard" value={search} onChange={(e) => { setSearch(e.target.value) }} />
                        {
                            showFilter && 
                                <Box sx={{ml:2, mr:0}}>
                                    <FormGroup>
                                        <FormControlLabel 
                                            control={<Switch checked={filterState} onChange={() => setFilterState(!filterState)} />}
                                            label={<Box sx={{fontSize:12}}>{filterLabel}</Box>}
                                            sx={{mr:0}}
                                        />
                                    </FormGroup>
                                    
                                </Box>
                        }
                    </Box>
                    <Box sx={{ 
                        width: '100%', 
                        overflowX: 'auto',
                        height: '100%', 
                        overflowY: 'hidden',
                        '&::-webkit-scrollbar': {
                            height: '16px'
                        },
                        '&::-webkit-scrollbar-track': {
                            backgroundColor: grey[200], 
                            borderRadius: '10px'
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: grey[400],
                            borderRadius: '10px',
                            border: `3px solid #ffffff`
                        }

                    
                    }}>
                        <LocationListSm locations={filteredLocations} changeHighlighted={changeHighlighted} highlighted={highlighted} search={search} />
                    </Box>
                </Box>
            }
        </Box>
    )
}

interface MapComponentProps {
    locations: CompanyLocationsForMap[],
    highlighted: string
}

function MapComponent({ locations, highlighted }: MapComponentProps) {
    const ref = useRef<HTMLDivElement>(null)
    const [map, setMap] = useState<google.maps.Map>();
    const [markers, setMarkers] = useState<google.maps.Marker[]>([]);

    // Map Component not used if locations.length == 0; 
    const firstLocation = locations[0];
    const center: google.maps.LatLngAltitudeLiteral = { lat: parseFloat(firstLocation.latitude), lng: parseFloat(firstLocation.longitude), altitude: 0 }
    const bounds = new google.maps.LatLngBounds()
    locations.forEach(el => {
        bounds.extend({ lat: parseFloat(el.latitude), lng: parseFloat(el.longitude) });
    })


    const iconBase = {
        path: "M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z",
        fillColor: "orange",
        fillOpacity: .8,
        strokeWeight: 0,
        strokeColor: "darkred",
        rotation: 0,
        scale: 5,
        anchor: new google.maps.Point(8, 8.5),
    }

    const normalIcon = { icon: { ...iconBase } };
    const highlightedIcon = { icon: { ...iconBase, fillOpacity: 1, strokeWeight: 3 } };

    // create the map after ref is assigned and if the map hasn't been created yet.  
    useEffect(() => {
        if (ref.current && !map) {
            const myMap = new google.maps.Map(ref.current!, {})
            setMap(myMap);
            if (locations.length > 1) {
                myMap.fitBounds(bounds);
            } else {
                myMap.setCenter(center)
                myMap.setZoom(9)
            }
        }
    })

    // assign markers if the map is created
    useEffect(() => {
        if (!map) return;

        // remove old markers from map and clear marker array
        markers.forEach(marker => {
            marker.setMap(null)
        })
        setMarkers([])

        let newCenter: google.maps.LatLngAltitudeLiteral | null = null;

        // create new markers and save to marker state array
        const newMarkerCollection: google.maps.Marker[] = [];
        locations.sort((a, b) => a.sys_id === highlighted ? 1 : -1)
        locations.forEach(el => {
            const infowindow = new google.maps.InfoWindow({
                content: `
                    <div style="color: black; padding-bottom: 8px;">
                        <b>${el.name}</b>
                    </div>
                    <div style="color: grey;">
                        ${el.street}
                    </div>
                    <div style="color: grey;">
                        ${el.city ? el.city + ", " : ""}${el.state}
                    </div>
                    <div style="color: grey;">
                        ${el.country}
                    </div>
                `,
                ariaLabel: "aria Label?",
            });

            // const iconChange = !highlighted ? highlightedIcon : el.sys_id === highlighted ? {} : normalIcon;
            const iconChange = highlighted && el.sys_id === highlighted ? highlightedIcon : normalIcon;
            const marker = new google.maps.Marker({
                icon: {
                    ...iconChange.icon, 
                    fillColor: el.dataPointCategoryColor || "orange",
                }, 
                position: { lat: parseFloat(el.latitude), lng: parseFloat(el.longitude) },
            })

            marker.setMap(map);
            marker.addListener("click", () => {
                infowindow.open({
                    anchor: marker,
                });
            });
            newMarkerCollection.push(marker);

            // find new center if highlighted or if only 1 location
            if((highlighted && el.sys_id === highlighted) || locations.length === 1){
                newCenter = {lat: parseFloat(el.latitude), lng: parseFloat(el.longitude), altitude: 0}
            }

        })
        setMarkers([...newMarkerCollection]);
        if (newCenter) {
            map.setCenter(newCenter)
            if((map.getZoom() || 1 ) < 13) map.setZoom(13)
        } else {
            map.fitBounds(bounds);
        }

    }, [locations, highlighted, map])

    return <Box sx={{ width: '100%', height: '100%'}} ref={ref} />;
}

interface LocationListProps {
    locations: CompanyLocationsForMap[]
    changeHighlighted: (id: string) => void;
    highlighted: string
    search: string
}

function LocationListLg({ locations, changeHighlighted, highlighted, search }: LocationListProps) {
    const clickableLocations: CompanyLocationsForMap[] = [];
    const nonClickableLocations: CompanyLocationsForMap[] = [];
    for (const el of locations) {
        const toSearch = `${el.name} ${el.street}, ${el.city}, ${el.state} ${el.country}`.toLowerCase();
        if (toSearch.includes(search.toLowerCase())) {
            const hasLatLon = parseFloat(el.longitude) && parseFloat(el.latitude);
            if (hasLatLon) clickableLocations.push(el);
            else nonClickableLocations.push(el);
        }
    }

    const createLocationItem = (el: CompanyLocationsForMap) => {
        const hasLatLon = parseFloat(el.longitude) && parseFloat(el.latitude);
        const extraColor = hasLatLon ? {} : { backgroundColor: grey[300] }
        const noHoverColor = hasLatLon ? {} : { backgroundColor: grey[300] }
        const highlightColor = highlighted !== el.sys_id ? {} : { backgroundColor: blueGrey[100], borderColor: 'black' }
        const highlightHover = highlighted !== el.sys_id ? {} : { backgroundColor: blueGrey[200] }

        const boxContent = <Box sx={{
            width: '100%',
            padding: 1,
            border: 1,
            borderColor: '#e6e6e6',
            borderRadius: 1,
            backgroundColor: grey[50],
            boxShadow: 1,
            '&:hover': { backgroundColor: '#EAEAEA', ...highlightHover, ...noHoverColor },
            ...extraColor,
            ...highlightColor
        }}>
            <Box sx={{color: 'black', textAlign: 'left', wordWrap: 'break-word' }}><b>{el.name}</b></Box>
            {el.subtitle_node || <></>}
            <Box sx={{ color: grey[700], textAlign: 'left', mt:1 }}>{`${el.street ? el.street + ", " : ""}${el.city ? el.city + ", " : ""}${el.state} ${el.country}`}</Box>
        </Box>

        return (
            <Box key={`${el.sys_id}-location-list-item`} >
                {
                    hasLatLon ?
                        <ButtonBase sx={{ py: 0, borderRadius: 1, my: .5, width: '100%' }} onClick={() => changeHighlighted(el.sys_id)} >
                            {boxContent}
                        </ButtonBase> :
                        <Tooltip title={<Box sx={{ textAlign: 'center' }}>Location latitude and longitude are not available in ServiceNow.</Box>} placement="top">
                            {/* Tooltip doesn't work with button disabled without a wrapping element */}
                            <div style={{ cursor: 'not-allowed' }}>
                                <ButtonBase sx={{ py: 0, borderRadius: 1, my: .5, width: '100%' }} disabled>
                                    {boxContent}
                                </ButtonBase>
                            </div>
                        </Tooltip>
                }
            </Box>
        )
    };

    return (
        <Box sx={{ bgcolor: 'white' }}>
            {clickableLocations.sort((a,b) => `${a.name}-${a.street}` < `${b.name}-${b.street}` ? -1 : 1).map(createLocationItem)}
            {nonClickableLocations.sort((a,b) => `${a.name}-${a.street}` < `${b.name}-${b.street}` ? -1 : 1).map(createLocationItem)}
        </Box>
    )
}

function LocationListSm({ locations, changeHighlighted, highlighted, search }: LocationListProps) {
    const clickableLocations: CompanyLocationsForMap[] = [];
    const nonClickableLocations: CompanyLocationsForMap[] = [];
    for (const el of locations) {
        const toSearch = `${el.name} ${el.street}, ${el.city}, ${el.state} ${el.country}`.toLowerCase();
        if (toSearch.includes(search.toLowerCase())) {
            const hasLatLon = parseFloat(el.longitude) && parseFloat(el.latitude);
            if (hasLatLon) clickableLocations.push(el);
            else nonClickableLocations.push(el);
        }
    }

    const createLocationItem = (el: CompanyLocationsForMap) => {
        const hasLatLon = parseFloat(el.longitude) && parseFloat(el.latitude);
        const extraColor = hasLatLon ? {} : { backgroundColor: grey[300] }
        const noHoverColor = hasLatLon ? {} : { backgroundColor: grey[300] }
        const highlightColor = highlighted !== el.sys_id ? {} : { backgroundColor: blueGrey[100], borderColor: 'black' }
        const highlightHover = highlighted !== el.sys_id ? {} : { backgroundColor: blueGrey[200] }

        const boxContent = <Box sx={{
            padding: 1,
            border: 1,
            borderColor: '#e6e6e6',
            borderRadius: 1,
            backgroundColor: grey[50],
            boxShadow: 1,
            '&:hover': { backgroundColor: '#EAEAEA', ...highlightHover, ...noHoverColor },
            ...extraColor,
            ...highlightColor
        }}>
            <Box sx={{ whiteSpace: 'nowrap', textAlign: 'left', color: "black" }}><b>{el.name}</b></Box>
            {el.subtitle_node || <></>}
            <Box sx={{ whiteSpace: 'nowrap', textAlign: 'left', color: grey[700], mt:1 }}>{el.street ? `${el.street}` : <br />}</Box>
            <Box sx={{ whiteSpace: 'nowrap', textAlign: 'left', color: grey[700] }}>
                {(!el.city && !el.state && !el.country) ? <br /> : <></>}
                {`${el.city ? el.city + ", " : ""} ${el.state} ${el.country}`}
            </Box>
        </Box>
        return (
            <Box key={`${el.sys_id}-location-list-item`} sx={{ mx: .5 }} >
                {
                    hasLatLon ?
                        <ButtonBase sx={{ py: 0, borderRadius: 1, my: 1 }} onClick={() => changeHighlighted(el.sys_id)} >
                            {boxContent}
                        </ButtonBase> :
                        <Tooltip title={<Box sx={{ textAlign: 'center' }}>Location latitude and longitude are not available in ServiceNow.</Box>} placement="top">
                            <div style={{ cursor: 'not-allowed' }}>
                                <ButtonBase sx={{ py: 0, borderRadius: 1, my: 1 }} disabled>
                                    {boxContent}
                                </ButtonBase>
                            </div>
                        </Tooltip>
                }
            </Box>
        )
    };

    return (
        <Box sx={{ bgcolor: 'white', display: 'flex', flexDirection: 'row'  }}>
            {clickableLocations.sort((a,b) => `${a.name}-${a.street}` < `${b.name}-${b.street}` ? -1 : 1).map(createLocationItem)}
            {nonClickableLocations.sort((a,b) => `${a.name}-${a.street}` < `${b.name}-${b.street}` ? -1 : 1).map(createLocationItem)}
        </Box>
    )
}



function ErrorComponent() {
    return <div>Sorry, there was an error loading locations.</div>
}

function LoadingCompaonent() {
    return (
        <Box sx={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress />
        </Box>
    )
}
