import { RecentOrdersContent } from "../RecentOrders";

import { Card, CardContent, Container, Grid } from "@mui/material";
//import UserDashboard from "./UserDashboard";
import OriginalMyCompanies from "./OriginalMyCompanies";

export default function HomePage() {
    return (
        <Container>
            <Grid container>
                {/* <Grid item xs={12}>
                    <Card sx={{m:.5}}>
                        <CardContent>
                            <UserDashboard />
                        </CardContent>
                    </Card>
                </Grid> */}
                <Grid item xs={12}>
                    <Card sx={{m:.5}}>
                        <CardContent>
                            <RecentOrdersContent showAllResults={false} />                                    
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sx={{px:.5}}>
                    {/* <Card sx={{m:.5}}>
                        <CardContent> */}
                            <OriginalMyCompanies />
                        {/* </CardContent>
                    </Card> */}
                </Grid>
            </Grid>
        </Container>
    )
}
