import { useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import InvoiceOrderSkeleton from '../../../components/Skeleton/InvoiceOrderSkeleton';
import type { Contract } from '../../../types';
import isTouchScreen from '../../../hooks/isTouchScreen';
import { addHashToWindowUrl, removeHashFromWindowUrl } from 'src/util/misc';

import { useCompanyIdFromPathAssertively } from 'src/hooks/useCompanyIdFromPath';
import { useContracts } from 'src/hooks/useContracts';
import assertively from 'src/util/assertively';

import ContractsDisplay from './ContractsDisplay';


export default function Contracts() {
    const [openContracts, setOpenContracts] = useState<string[]>([]);
    const companyId = useCompanyIdFromPathAssertively();
    const contracts = assertively(useContracts(companyId));
    const hash = useLocation().hash.replace("#", "");

    useEffect(() => {
        if (hash) setOpenContracts([hash]);
    }, [hash]); 

    const onContractClick = (contractNumber: string) => {
        if (openContracts.includes(contractNumber)) {
            setOpenContracts(openContracts.filter((num) => num !== contractNumber));
            removeHashFromWindowUrl();
        } else {
            setOpenContracts([...openContracts, contractNumber]);
            addHashToWindowUrl(contractNumber);
        }
    }

    if(!contracts) return <InvoiceOrderSkeleton count={10} />;
    const sortedContracts = sortContracts(contracts);

    return (
        <ContractsDisplay
            contracts={sortedContracts}
            numberOpen={hash}
            onChangeFn={onContractClick}
            isTouchScreen={isTouchScreen()}
            openContracts={openContracts}
        />
    )
}


function sortContracts(contracts: Contract[]): Contract[] {
    return contracts.sort((a, b) => {
        const aDate = new Date(a.starts);
        const bDate = new Date(b.starts);
        return aDate > bDate ? -1 : 1;
    });
}



