import Typography from "@mui/material/Typography";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { InteractionStatus, InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import { createConfig } from "./routes";
import LoginLoadingComponent from "./pages/Home/LoginLoadingComponents";

import type { MsalAuthenticationResult } from "@azure/msal-react";

const queryClient = new QueryClient();

/**
 * Note that it is extremely important that this component does not call `createBrowserRouter` unless
 * we are sure that the msal authentication is complete. Otherwise, any call to `createBrowserRouter`
 * creates a _live_ router, which will immediately start evaluating the current URL, which may result
 * in loaders being called before our msal authentication is actually complete.
 */
function AuthorizedRouter() {
    return (useMsal().inProgress === InteractionStatus.None)
        ? (
            <RouterWithBackend />
        ) : (
            <LoginLoadingComponent />
        );
}

function RouterWithBackend() {
    return (
            <QueryClientProvider client={queryClient}>
                <RouterProvider
                    router={createBrowserRouter(createConfig())}
                />
            </QueryClientProvider>
    );
}                        

function LoginErrorComponent(params: MsalAuthenticationResult) {
    return (
        <>
            <Typography variant="h6">
                An error occurred: {params.error ? params.error.errorCode : "unknown error"}
            </Typography>
        </>
    );
};




export default function App() {
    const authRequest = {
        scopes: process.env.REACT_APP_AZURE_SCOPES!.split(",").map(s => s.trim())
    };

    return (
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={authRequest}
            errorComponent={LoginErrorComponent}
            loadingComponent={LoginLoadingComponent}
        >
            <AuthorizedRouter />
        </MsalAuthenticationTemplate >
    );
}